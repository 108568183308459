var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "header-container flex-row-center f-between mb20" },
      [
        _c("div", { staticClass: "flex-row-center" }, [
          _vm._v(" " + _vm._s(_vm.$t("issueManager.issue")) + " "),
        ]),
        _c(
          "div",
          {
            staticClass: "flex-row-center pointer back-list",
            on: { click: _vm.goBack },
          },
          [
            _c("i", { staticClass: "iconfont icon-fanhui mr8 " }),
            _vm._v(
              " " +
                _vm._s(
                  _vm.isCarry
                    ? _vm.$t("issueVideo.backCarry")
                    : _vm.$t("backList")
                ) +
                " "
            ),
          ]
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      },
      _vm._l(_vm.forms, function (form, index) {
        return _c("issue-item", {
          key: index,
          ref: "issueItems",
          refInFor: true,
          attrs: {
            index: index,
            accountList: _vm.accountList,
            newAccounts: _vm.newAccounts,
            timeOptions: _vm.timeOptions,
            languageOptions: _vm.languageOptions,
            languageList: _vm.languageList,
            addType: _vm.addType,
            baseForm: _vm.baseForm,
            isCarry: _vm.isCarry,
            carryData: _vm.carryData,
          },
          model: {
            value: _vm.forms[index],
            callback: function ($$v) {
              _vm.$set(_vm.forms, index, $$v)
            },
            expression: "forms[index]",
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }