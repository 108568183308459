<template>
  <div class="info-container">
    <!-- <div class="headerSelect f-between">
      <el-checkbox-group
        v-model="mediaTypeList"
        size="medium"
      >
        <el-checkbox
          v-for="item in dict.type.social_platform"
          :key="item.value"
          :label="item.label"
          border
        ></el-checkbox>
      </el-checkbox-group>
      <el-button
        circle
        icon="el-icon-refresh"
        @click="getInfoRefresh"
      ></el-button>
    </div> -->
    <!-- <facebook-video videoUrl="https://www.facebook.com/watch?v=3837792039871953"></facebook-video> -->
    <div class="flexMessage">
      <info-social :mediaTypeList="mediaTypeList" />
      <div class="rightMessage">
        <div
          v-if="isShowMsg"
          class="infoStreamMsgContent flex-column-center"
          v-loading="socialLoading"
          style="height: 100%; overflow-y: auto;"
        >
          <div>
            <div
              v-for="item in socialList"
              :key="item.id"
            >
              <!-- <iframe
                :src="item.embedUrl"
                frameborder="0"
                allowfullscreen
                class="video"
                height="300px"
                width="500px"
              ></iframe> -->
              <!-- v-if="item.postUrl"  -->
              <TiktokItem
                :item="item"
                :avatarUrl="avatarUrl"
                :username="username"
                :platform="platform"
                :socialId="queryParamsSocial.socialId"
                style="margin-bottom: 5px"
              />
            </div>
          </div>
          <el-empty
            :image-size="200"
            v-if="socialList.length==0"
          ></el-empty>
        </div>
        <div
          v-else
          class="infoStreamMsgContent flex-center"
        >
          <img
            v-if="logo"
            :src="logo"
            class="logo"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryInformationFlow } from "../../api/ai/publish";
import TiktokItem from "./tiktokItem.vue";
import InfoSocial from "./info-social.vue";
import logoImg from "@/assets/logo/logo.png";
import EventBus from "@/utils/EventBus";
import FacebookVideo from "./components/FacebookVideo.vue";

export default {
  // name: "InfoStream",
  components: {
    TiktokItem,
    InfoSocial,
    FacebookVideo
  },
  dicts: ["social_platform"],

  data() {
    return {
      logo: logoImg,
      mediaTypeList: [], //选中的媒体类型
      isShowMsg: false,
      loading: false,
      queryParamsSocial: {
        socialId: "",
        id: "",
        searchValue: "", //关键词
        dateRange: "", //时间范围
        languageId: "", //语言id
      },
      socialList: [],
      socialLoading: false,
      avatarUrl: "",
      username: "",
      platform: "",
    };
  },
  created() {
    this.queryParamsSocial.id = this.$route.query.id || "";
    this.queryParamsSocial.socialId = this.$route.query.socialId || "";
  },
  mounted() {
    EventBus.$on("infoStream-detail", (info) => {
      this.isShowMsg = true;
      this.queryParamsSocial.socialId = info.id;
      this.avatarUrl = info.userImage;
      this.username =
        info.platform == "facebook" ? info.pageName : info.username;
      this.platform = info.platform;
      this.getInfoByTiktok();
    });
  },
  beforeDestroy() {
    EventBus.$off("infoStream-detail", this.getInfoByTiktok);
  },
  methods: {
    resetQuery() {
      this.queryParamsSocial.id = "";
      this.queryParamsSocial.searchValue = "";
      this.queryParamsSocial.dateRange = "";
      this.queryParamsSocial.languageId = "";
      this.getInfoByTiktok();
    },
    getInfoByTiktok() {
      this.socialLoading = true;
      queryInformationFlow(this.queryParamsSocial)
        .then((response) => {
          console.log(response);
          this.socialList = response.data || [];
        })
        .finally(() => {
          this.socialLoading = false;
        });
    },
    //右上角刷新
    getInfoRefresh() {
      this.getInfoByTiktok();
    },
  },
};
</script>
<style lang="scss" scoped>
.info-container {
  padding: 24px;
  // height: 90vh;

  .headerSelect {
    background: linear-gradient(
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.2) 100%
    );
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.02);
    border-radius: 6px;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    .el-checkbox {
      font-size: 14px;
      margin-right: 22px;
      margin-left: 0px;
    }
    .el-checkbox.is-bordered.el-checkbox--medium {
      padding: 11px 20px 7px 10px;
      width: 140px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      margin-left: 0px;
    }
    .el-checkbox.is-bordered.is-checked {
      border-color: #0156ff;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #0156ff;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #0156ff;
      border-color: #0156ff;
    }
  }
}
.flexMessage {
  display: flex;
  // margin-top: 16px;
  .info-social {
    width: 320px;
    height: calc(100vh - 130px);
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    border: 1px solid #ebebeb;
    margin-right: 16px;
    overflow: hidden;
  }
  .rightMessage {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 130px);
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    border: 1px solid #ebebeb;
    padding: 16px;
    overflow: hidden;
    .infoStreamMsgContent {
      width: 100%;
      height: 100%;
      background: #e7eef5;
      border-radius: 10px;
      padding: 16px;
      .logo {
        opacity: 0.2;
        width: 160px;
      }
    }
  }
}

.video {
  align-self: center;
  margin-top: 10px;
}
</style>
