<template>
  <div class="video-page">
    <!-- 主容器 -->
    <div
      class="video-container flex-center"
      v-loading="loading"
    >
      <!-- 视频播放区域 -->
      <div
        class="carousel-container"
        ref="carouselContainer"
      >
        <div class="flex-row">
          <div class="video-frame-container flex-column f-between">
            <video
              ref="videoRefs"
              :src="detail.url"
              controls
              autoplay
              class="video-player-info"
            ></video>
            <!-- muted -->
            <div class="bottom-content flex-row-center f-between f-shrink">
              <el-avatar
                :size="48"
                :src="detail.socialAvatar"
                class="f-shrink"
              ></el-avatar>
              <span class="name ml10 f-grow single-line-ellipsis">{{ detail.socialAccount }}</span>
              <span class="time ml10 f-shrink">{{ timeAgo(detail.publishTime) }}</span>
            </div>
          </div>
          <!-- 平台logo -->
          <div class="video-nums flex-column-center f-between">
            <div class="icon-container flex-center">
              <i
                class="iconfont socialType-icon"
                :class="['icon-' + detail.platform, detail.platform]"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPublishDetail } from "../../api/ai/publish";

export default {
  name: "IssueDetail",
  data() {
    return {
      loading: false,
      id: null,
      detail: {},
    };
  },

  mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.loading = true;
      getPublishDetail(this.id)
        .then((response) => {
          this.detail = response.data || {};
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.video-page {
  padding: 15px;
  // margin: 15px;
  // width: 100%;
  height: calc(100vh);
  overflow: hidden;
  border-radius: 10px;
}

.video-container {
  width: 100%;
  height: calc(100vh - 30px);
  background: #fafcfd;
  border-radius: 10px;
  box-sizing: border-box;
  // overflow: hidden;
}
.carousel-container {
  height: calc(100vh - 60px); /* 固定高度为90% */
  width: calc((100vh - 60px) * 9 / 16);
  overflow: hidden;
}

.video-frame-container {
  height: calc(100vh - 60px); /* 固定高度为90% */
  width: calc((100vh - 60px) * 9 / 16);
  overflow: hidden;
  margin: 0;
}
.video-player-info {
  width: 100%;
  height: calc(100vh - 140px);
  border: none;
  border-radius: 10px;
  overflow: hidden; /* 隐藏滚动条 */
  display: block;
  scrollbar-width: none;
  background: #000;
}

.bottom-content {
  width: 100%;
  height: 80px;
  padding: 0 16px;
  z-index: 10;

  .name {
    height: 20px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 20px;
    text-align: left;
    font-style: normal;
  }
  .time {
    height: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 18px;
    text-align: left;
    font-style: normal;
  }
}

.video-nums {
  width: 48px;
  margin-left: 24px;
  height: calc(100vh - 340px);
  align-self: flex-start;
  padding-top: 8px;

  .icon-container {
    align-self: flex-start;
    width: 48px;
    height: 48px;
    border-radius: 24px; /* 圆角 */
    overflow: hidden; /* 确保内容裁剪 */
    box-sizing: border-box; /* 防止 padding 影响尺寸 */

    .socialType-icon {
      font-size: 48px;
    }
  }
}

@media screen and (max-height: 900px) {
  .video-nums {
    width: 40px;
    margin-left: 24px;
    height: calc(100vh - 320px);
    align-self: flex-start;
    padding-top: 8px;

    .icon-container {
      align-self: flex-start;
      width: 40px;
      height: 40px;
      border-radius: 50%; /* 圆角 */
      overflow: hidden; /* 确保内容裁剪 */
      box-sizing: border-box; /* 防止 padding 影响尺寸 */

      .socialType-icon {
        font-size: 40px;
      }
    }
  }
}
@media screen and (max-height: 768px) {
  .video-nums {
    width: 32px;
    margin-left: 24px;
    height: calc(100vh - 320px);
    align-self: flex-start;
    padding-top: 8px;

    .icon-container {
      align-self: flex-start;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden; /* 确保内容裁剪 */
      box-sizing: border-box; /* 防止 padding 影响尺寸 */

      .socialType-icon {
        font-size: 32px;
      }
    }
  }
}
</style>
