var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-manager" }, [
    _c(
      "div",
      {
        staticClass: "flex-row-center f-between mb16",
        staticStyle: { height: "48px" },
      },
      [
        _c(
          "div",
          { staticClass: "leftActiveTab" },
          _vm._l(_vm.tabList, function (item) {
            return _c(
              "div",
              {
                key: item.label,
                class: ["tabItem", item.isActive && "activeTab"],
                on: {
                  click: function ($event) {
                    return _vm.clickSearchTable(item)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.label) + " ")]
            )
          }),
          0
        ),
        _vm.quota.publishCount
          ? _c(
              "div",
              { staticClass: "flex-row-center" },
              [
                _c("span", { staticClass: "mr5" }, [
                  _vm._v(_vm._s(_vm.$t("quota.quota")) + "："),
                ]),
                _c("Slider", {
                  attrs: {
                    value: _vm.formatQuota(_vm.quota.usePublishCount) || 0,
                    maxValue: _vm.formatQuota(_vm.quota.publishCount) || "100",
                  },
                }),
                _c("div", { staticClass: "ml5" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.quota.usePublishCount || 0) +
                      "/" +
                      _vm._s(_vm.quota.publishCount || 100) +
                      _vm._s(_vm.$t("quota.item")) +
                      " "
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      { staticClass: "videoContent" },
      [
        _c("div", { staticClass: "flex-row-center f-between w-100 mb32" }, [
          _c(
            "div",
            { staticClass: "f-shrink" },
            [
              _c(
                "el-button",
                {
                  staticClass: "gradient-button",
                  attrs: { icon: "el-icon-plus" },
                  on: { click: _vm.createVideo },
                },
                [_vm._v(_vm._s(_vm.$t("issueManager.issue")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex-row-center" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: _vm.$t("refresh"),
                    placement: "top",
                  },
                },
                [
                  _c("i", {
                    staticClass: "iconfont icon-a-danyuanpeizhi6",
                    on: { click: _vm.init },
                  }),
                ]
              ),
              _c(
                "div",
                { staticStyle: { width: "400px", margin: "0 5px" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "account-input",
                      attrs: {
                        placeholder: _vm.$t("issueManager.searchTip"),
                        clearable: "",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleQuery($event)
                        },
                      },
                      model: {
                        value: _vm.queryParams.searchValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "searchValue", $$v)
                        },
                        expression: "queryParams.searchValue",
                      },
                    },
                    [
                      _c("i", {
                        staticClass:
                          "el-input__icon iconfont icon-sousuotubiao",
                        attrs: { slot: "prefix" },
                        on: { click: _vm.handleQuery },
                        slot: "prefix",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              "header-row-class-name": "custom_table_header",
              height: "calc(100vh - 320px)",
              data: _vm.videos,
              "row-style": _vm.setRowStyle,
            },
            on: {
              "cell-mouse-enter": _vm.cellMouseEnter,
              "cell-mouse-leave": _vm.cellMouseLeave,
              "filter-change": _vm.filterChange,
            },
          },
          [
            _c("el-empty", {
              attrs: { slot: "empty", "image-size": 180 },
              slot: "empty",
            }),
            _c("el-table-column", {
              attrs: {
                prop: "postContent",
                label: _vm.$t("issueManager.post"),
                align: "left",
                "min-width": "300",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      !scope.row.url
                        ? _c("div", { staticClass: "noVideo" }, [
                            _c("div", { staticClass: "flexBox" }, [
                              _c("i", {
                                staticClass: "iconfont icon-xialajiantou1",
                              }),
                              _c("div", { staticClass: "fontStyle" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("issueManager.notUploaded"))
                                ),
                              ]),
                            ]),
                          ])
                        : _c(
                            "div",
                            { staticClass: "tableLeftVideo" },
                            [
                              _c(
                                "div",
                                { staticClass: "cellVideo" },
                                [
                                  _c("video", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: { src: scope.row.url },
                                  }),
                                  _c(
                                    "video-button",
                                    {
                                      attrs: {
                                        url: scope.row.url,
                                        tip: _vm.$t("issueManager.playVideo"),
                                      },
                                      on: {
                                        closeViewVideo: _vm.closeViewVideo,
                                        viewVideo: _vm.viewVideo,
                                      },
                                    },
                                    [
                                      _c("template", { slot: "customButton" }, [
                                        _c("div", { staticClass: "cricle" }, [
                                          _c("i", {
                                            staticClass: "el-icon-caret-right",
                                          }),
                                        ]),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content: scope.row.postContent,
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "post-content" }, [
                                    _vm._v(
                                      _vm._s(scope.row.postContent || "--")
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                "column-key": "topHeader",
                prop: "topHeader",
                label: _vm.$t("account.user"),
                filters: _vm.profileFilters,
                "filter-multiple": false,
                "min-width": "120",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "single-line-ellipsis" }, [
                        _vm._v(" " + _vm._s(scope.row.topHeader) + " "),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                "min-width": "120px",
                "column-key": "socialType",
                prop: "socialType",
                label: _vm.$t("issueManager.platform"),
                filters: _vm.socialPlatformFilters,
                "filter-placement": "bottom",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "flex-row-center",
                          staticStyle: { padding: "5px" },
                        },
                        [
                          scope.row.socialType
                            ? _c("i", {
                                staticClass: "iconfont mr5 font20",
                                class: [
                                  "icon-" + scope.row.socialType + "-normal",
                                  scope.row.socialType,
                                ],
                              })
                            : _vm._e(),
                          scope.row.socialType
                            ? _c("dict-tag", {
                                staticClass: "f-shrink",
                                attrs: {
                                  options: _vm.dict.type.social_platform,
                                  value: scope.row.socialType,
                                },
                              })
                            : _c("span", [_vm._v("--")]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                "min-width": "180px",
                "column-key": "account",
                prop: "account",
                label: _vm.$t("issueManager.social"),
                filters: _vm.filterAccountList,
                "filter-multiple": false,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          style: scope.row.isHoverCell
                            ? "opacity: 0"
                            : "opacity: 1",
                        },
                        [_vm._v(_vm._s(scope.row.account || "--"))]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.row.isHoverCell,
                              expression: "scope.row.isHoverCell",
                            },
                          ],
                          staticClass: "flex-row-center operate-hover",
                        },
                        [
                          scope.row.status == "0"
                            ? _c(
                                "div",
                                {
                                  staticClass: "flex-row-center list-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editVideo(scope.row, "edit")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont icon-edit",
                                  }),
                                  _vm._v(" " + _vm._s(_vm.$t("edit")) + " "),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "flex-row-center list-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editVideo(scope.row, "add")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "iconfont icon-xingzhuangjiehe",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("issueManager.republish")) +
                                      " "
                                  ),
                                ]
                              ),
                          scope.row.status == "1" &&
                          scope.row.socialPostStatus != "delete"
                            ? _c(
                                "div",
                                {
                                  staticClass: "flex-row-center list-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCancel(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "iconfont icon-a-xialajiantoubeifen2",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("issueManager.cancelPublish")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          scope.row.status == "2" &&
                          scope.row.socialPostStatus != "delete"
                            ? _c(
                                "div",
                                {
                                  staticClass: "flex-row-center list-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleBackout(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "iconfont icon-a-xialajiantoubeifen2",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("issueManager.unpublish")) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "flex-row-center list-button",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(scope.row)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "iconfont icon-delete" }),
                              _vm._v(" " + _vm._s(_vm.$t("remove")) + " "),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                "min-width": "110px",
                prop: "publishTimeType",
                label: _vm.$t("issueManager.type"),
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("dict-tag", {
                        style: scope.row.isHoverCell
                          ? "opacity: 0"
                          : "opacity: 1",
                        attrs: {
                          options: _vm.dict.type.publish_time_type,
                          value: scope.row.publishTimeType,
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "status",
                label: _vm.$t("issueManager.status"),
                align: "center",
                "min-width": "270",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "status",
                          class: "status-" + scope.row.status,
                          style: scope.row.isHoverCell
                            ? "opacity: 0"
                            : "opacity: 1",
                        },
                        [
                          scope.row.status == 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("issueManager.statusDraft"))
                                ),
                              ])
                            : _vm._e(),
                          scope.row.status == 1
                            ? _c("span", [
                                _c("i", {
                                  staticClass: "iconfont icon-dacha1",
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("issueManager.publishing")) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          scope.row.status == 2
                            ? _c("span", [
                                _c("i", {
                                  staticClass: "iconfont icon-a-dacha3",
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("issueManager.published")) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          scope.row.status == 3
                            ? _c(
                                "div",
                                { staticClass: "hidden flex-column-center" },
                                [
                                  _c("div", { staticClass: "flex-center" }, [
                                    _c("i", {
                                      staticClass: "iconfont icon-a-dacha2",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("issueManager.failed")) +
                                        " "
                                    ),
                                  ]),
                                  _c("span", { staticClass: "status-des" }, [
                                    _vm._v(_vm._s(scope.row.errorMessage)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                sortable: "",
                label: _vm.$t("time.createTime"),
                align: "center",
                prop: "createTime",
                width: "150",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          style: scope.row.isHoverCell
                            ? "opacity: 0"
                            : "opacity: 1",
                        },
                        [_vm._v(_vm._s(scope.row.createTime))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                sortable: "",
                label: _vm.$t("issueManager.time"),
                align: "center",
                prop: "publishTime",
                width: "150",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          style: scope.row.isHoverCell
                            ? "opacity: 0"
                            : "opacity: 1",
                        },
                        [_vm._v(_vm._s(scope.row.publishTime))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "taskName",
                label: _vm.$t("issueManager.taskName"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          style: scope.row.isHoverCell
                            ? "opacity: 0"
                            : "opacity: 1",
                        },
                        [_vm._v(_vm._s(scope.row.taskName))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0",
            },
          ],
          attrs: {
            total: _vm.total,
            page: _vm.queryParams.pageNum,
            limit: _vm.queryParams.pageSize,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.queryParams, "pageNum", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.queryParams, "pageSize", $event)
            },
            pagination: _vm.getList,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }