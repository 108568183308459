<template>
  <div class="info-social">
    <div class="topPadding">
      <div class="topTitle">{{ $t('publicationStream.title') }}</div>
      <el-input
        class="inputStyle"
        v-model="searchValue"
        :placeholder="$t('account.tipAccount')"
        clearable
        @keyup.enter.native="handleQuery"
      >
        <i
          slot="prefix"
          class="el-input__icon iconfont icon-sousuotubiao"
          @click="handleQuery"
        ></i>
      </el-input>
    </div>
    <!-- 账号折叠面板 -->
    <el-collapse
      v-model="activeNames"
      v-loading="loading"
    >
      <div
        v-for="(item, parentIndex) in accountList"
        :key="item.parentIndex"
      >
        <el-collapse-item
          :name="item.platform"
          v-if="item.data && item.data.length"
        >
          <template #title>
            <div class="accountTitle">
              <div class="criceIconUser">
                <i
                  class="iconfont font12"
                  :class="['icon-' + item.platform + '-normal']"
                  style="font-size: 10px;color: #fff;"
                ></i>
              </div>
              <dict-tag
                class="f-shrink"
                :options="dict.type.social_platform"
                :value="item.platform"
              />
            </div>
          </template>
          <div
            v-for="(account, childIndex) in item.data"
            :key="account.id"
            :class="['accountBox', account.isActive ? 'activeBox' : '']"
            @click="clickAccount(parentIndex, childIndex)"
          >
            <div class="leftAvatar">
              <el-avatar
                :size="44"
                :src="account.userImage"
              ></el-avatar>
              <div class="criceIcon">
                <i
                  :class="['iconfont', 'icon-' +account.platform + '-normal', account.platform]"
                  style="font-size: 10px;"
                ></i>
              </div>
            </div>
            <div class="rightDetail">
              <div class="detailTop">
                <div class="head-title">{{ account.platform == 'facebook'? account.pageName :account.username }}</div>
                <!-- <div class="time">{{ account.updateTime }}</div> -->
              </div>
            </div>
          </div>
        </el-collapse-item>
      </div>
      <el-empty
        :description="$t('account.notData')"
        :image-size="100"
        v-if="accountList.length==0"
      >
        <el-button
          type="primary"
          size="small"
          @click="goBind"
        >{{ $t('account.goBind') }}</el-button>
      </el-empty>
    </el-collapse>
  </div>
</template>

<script>
import EventBus from "@/utils/EventBus";
import { mapState } from "vuex";
export default {
  name: "Info-social",
  components: {},
  dicts: ["social_platform"],
  props: {
    mediaTypeList: {
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      searchValue: "",
      platform: [],
      activeNames: "1",
      loading: false,
    };
  },
  created() {
    this.searchFun();
  },
  watch: {
    mediaTypeList: {
      handler(newVal) {
        this.platform = newVal;
        this.searchFun();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      accountList: (state) => state.infoStream.accountList,
    }),
  },
  methods: {
    goBind() {
      this.$router.push({ path: '/account' });
    },
    handleQuery() {
      this.searchFun();
    },
    searchFun() {
      this.loading = true;
      this.$store
        .dispatch("infoStream/getAccountList", {
          platform: this.platform.length ? this.platform.join(",") : "",
          searchValue: this.searchValue,
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clickAccount(t, n) {
      const info = this.accountList[t].data[n];
      this.accountList.forEach((item) => {
        const { data } = item;
        if (data && data.length) {
          data.forEach((item) => {
            item.isActive = false;
          });
        }
      });
      console.log(info);

      this.accountList[t].data[n].isActive = true;
      EventBus.$emit("infoStream-detail", info);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
.info-social {
  .topPadding {
    padding: 14px 16px;
    .topTitle {
      font-family: PingFang-SC, PingFang-SC;
      font-weight: bold;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 20px;
      text-align: left;
      font-style: normal;
      margin-bottom: 22px;
    }
    .inputStyle {
      background: #ffffff;
      border-radius: 8px;
      .el-input__inner {
        height: 32px;
        border-radius: 8px !important;
      }
      .el-input__icon {
        line-height: 32px;
      }
    }
  }
  .el-collapse {
    border: none;
    height: 70%;
    overflow: auto;
    padding: 0 16px;
    @include chatScrollBar;
    .el-collapse-item__header {
      border-bottom: 1px solid transparent;
    }
    .el-collapse-item__wrap {
      border-bottom: 1px solid transparent;
    }
    .accountTitle {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #016fee;
      line-height: 24px;
      text-align: left;
      font-style: normal;
      display: flex;
      align-items: center;
      .criceIconUser {
        width: 24px;
        height: 24px;
        background: #016fee;
        border-radius: 57px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .accountBox {
      cursor: pointer;
      width: 100%;
      height: 64px;
      background: #ffffff;
      padding: 0 10px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .leftAvatar {
        width: 45px;
        height: 45px;
        position: relative;
        .criceIcon {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #fff;
          border: 1px solid #dcdcdc;
          position: absolute;
          bottom: -3px;
          right: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          .iconfont {
            font-size: 12px;
          }
          .icon-youtube-normal {
            font-size: 10px;
          }
        }
      }
      .rightDetail {
        width: 100%;
        margin-left: 10px;
        .detailTop {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .head-title {
            font-family: PingFang-SC, PingFang-SC;
            font-weight: bold;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.8);
            line-height: 20px;
            text-align: left;
            font-style: normal;
          }
          .time {
            font-family: PingFang-SC, PingFang-SC;
            font-weight: 400;
            font-size: 12px;
            color: #bfccdf;
            line-height: 17px;
            text-align: left;
            font-style: normal;
          }
        }
        .detailMsg {
          margin-top: 8px;
          width: 190px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.6);
          line-height: 20px;
          text-align: left;
          font-style: normal;
        }
      }
    }
    .activeBox {
      background: #f2f8fe;
      .criceIcon {
        border: 1px solid #0156ff !important;
      }
    }
  }

  .icon-quanbuzhanghao {
    color: #0156ff !important;
  }
}
</style>
