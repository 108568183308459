<template>
  <div class="video-manager">
    <!-- <el-row class="flex-row-center">
      <el-col :span="21">
        <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch"
          label-width="68px">
          <el-form-item prop="username">
            <el-input v-model="queryParams.username" :placeholder="$t('issueManager.social')" style="width: 240px" clearable
              @keyup.enter.native="handleQuery" />
          </el-form-item>
          <el-form-item prop="publishTimeType">
            <el-select v-model="queryParams.publishTimeType" :placeholder="$t('issueManager.type')" clearable @change="handleQuery" style="width: 240px">
              <el-option v-for="dict in dict.type.publish_time_type" :key="dict.value" :label="dict.label"
                :value="dict.value" />
            </el-select>
          </el-form-item>
          <el-form-item prop="status">
            <el-select v-model="queryParams.status" :placeholder="$t('issueManager.status')" clearable @change="handleQuery" style="width: 240px">
              <el-option v-for="dict in dict.type.issue_status" :key="dict.value" :label="dict.label"
                :value="dict.value" />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('issueManager.time')">
            <el-date-picker v-model="queryParams.dateRange" @change="handleQuery" style="width: 240px" value-format="yyyy-MM-dd" type="daterange"
              range-separator="-" :start-placeholder="$t('time.startDate')" :end-placeholder="$t('time.endingDate')"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">{{$t('search')}}</el-button>
            <el-tooltip class="item" effect="dark" :content="$t('refresh')" placement="top">
              <el-button size="mini" circle icon="el-icon-refresh" @click="init" />
            </el-tooltip>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="3">
        <div v-if="quota.publishCount">
         {{$t('quota.quota')}}：{{ quota.usePublishCount || 0}}/{{ quota.publishCount || 100}}
        </div>
        <el-button type="success" @click="createVideo">+ {{$t('issueManager.issue')}}</el-button>
      </el-col>
    </el-row> -->
    <div
      class="flex-row-center f-between mb16"
      style="height: 48px;"
    >
      <div class="leftActiveTab">
        <div
          v-for="item in tabList"
          :key="item.label"
          :class="['tabItem', item.isActive && 'activeTab']"
          @click="clickSearchTable(item)"
        >
          <!-- <i :class="['iconfont', item.iconFont]"></i> -->
          {{ item.label }}
        </div>
      </div>
      <div
        v-if="quota.publishCount"
        class="flex-row-center"
      >
        <span class="mr5">{{$t('quota.quota')}}：</span>
        <Slider
          :value="formatQuota(quota.usePublishCount) || 0"
          :maxValue="formatQuota(quota.publishCount) ||'100' "
        />
        <div class="ml5">
          {{ quota.usePublishCount || 0}}/{{ quota.publishCount || 100}}{{ $t('quota.item') }}
        </div>
      </div>
    </div>
    <div class="videoContent">
      <div class="flex-row-center f-between w-100 mb32">
        <div class="f-shrink">
          <el-button
            class="gradient-button"
            icon="el-icon-plus"
            @click="createVideo"
          >{{ $t('issueManager.issue') }}</el-button>
        </div>
        <div class="flex-row-center">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('refresh')"
            placement="top"
          >
            <i
              class="iconfont icon-a-danyuanpeizhi6"
              @click="init"
            ></i>
          </el-tooltip>
          <div style="width: 400px;margin:0 5px;">
            <el-input
              class="account-input"
              v-model="queryParams.searchValue"
              :placeholder="$t('issueManager.searchTip')"
              @keyup.enter.native="handleQuery"
              clearable
            >
              <i
                slot="prefix"
                class="el-input__icon iconfont icon-sousuotubiao"
                @click="handleQuery"
              ></i>
            </el-input>
          </div>

        </div>
      </div>
      <el-table
        header-row-class-name="custom_table_header"
        height="calc(100vh - 320px)"
        :data="videos"
        v-loading="loading"
        :row-style="setRowStyle"
        @cell-mouse-enter="cellMouseEnter"
        @cell-mouse-leave="cellMouseLeave"
        @filter-change="filterChange"
      >
        <el-empty
          :image-size="180"
          slot="empty"
        ></el-empty>
        <el-table-column
          prop="postContent"
          :label="$t('issueManager.post')"
          align="left"
          min-width="300"
        >
          <template slot-scope="scope">
            <div
              v-if="!scope.row.url"
              class="noVideo"
            >
              <div class="flexBox">
                <i class="iconfont icon-xialajiantou1"></i>
                <div class="fontStyle">{{ $t('issueManager.notUploaded') }}</div>
              </div>
            </div>
            <div
              v-else
              class="tableLeftVideo"
            >
              <div class="cellVideo">
                <video
                  style="width: 100%; height: 100%;"
                  :src="scope.row.url"
                ></video>
                <video-button
                  @closeViewVideo="closeViewVideo"
                  @viewVideo="viewVideo"
                  :url="scope.row.url"
                  :tip="$t('issueManager.playVideo')"
                >
                  <template slot="customButton">
                    <div class="cricle">
                      <i class="el-icon-caret-right"></i>
                    </div>
                  </template>
                </video-button>
              </div>
              <el-tooltip
                effect="dark"
                :content="scope.row.postContent"
                placement="top"
              >
                <span class="post-content">{{ scope.row.postContent || "--" }}</span>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          column-key="topHeader"
          prop="topHeader"
          :label="$t('account.user')"
          :filters="profileFilters"
          :filter-multiple="false"
          min-width="120"
        >
          <template slot-scope="scope">
            <div class="single-line-ellipsis">
              {{ scope.row.topHeader }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="120px"
          column-key="socialType"
          prop="socialType"
          :label="$t('issueManager.platform')"
          :filters="socialPlatformFilters"
          filter-placement="bottom"
        >
          <template slot-scope="scope">
            <div
              class="flex-row-center"
              style="padding: 5px"
            >
              <i
                v-if="scope.row.socialType"
                class="iconfont mr5 font20"
                :class="['icon-' + scope.row.socialType + '-normal', scope.row.socialType]"
              ></i>
              <dict-tag
                v-if="scope.row.socialType"
                class="f-shrink"
                :options="dict.type.social_platform"
                :value="scope.row.socialType"
              />
              <span v-else>--</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="totalDuration" label="贴文类型"></el-table-column> -->
        <el-table-column
          min-width="180px"
          column-key="account"
          prop="account"
          :label="$t('issueManager.social')"
          :filters="filterAccountList"
          :filter-multiple="false"
        >
          <template slot-scope="scope">
            <!-- <span>{{ getAccount(scope.row.socialId) }}</span> -->
            <span :style="scope.row.isHoverCell ? 'opacity: 0' : 'opacity: 1'">{{ scope.row.account || "--" }}</span>
            <div
              v-show="scope.row.isHoverCell"
              class="flex-row-center operate-hover"
            >
              <div
                v-if="scope.row.status == '0'"
                class="flex-row-center list-button"
                @click="editVideo(scope.row,'edit')"
              >
                <i class="iconfont icon-edit"></i>
                {{ $t('edit') }}
              </div>
              <div
                v-else
                class="flex-row-center list-button"
                @click="editVideo(scope.row,'add')"
              >
                <i class="iconfont icon-xingzhuangjiehe"></i>
                {{ $t('issueManager.republish') }}
              </div>

              <!-- <span  v-else style="display: inline-block;" class="mr10">
                <video-button @closeViewVideo="closeViewVideo" @viewVideo="viewVideo" :url="scope.row.url" :tip="$t('issueManager.playVideo')">
                  <template slot="customButton">
                    <el-button style="color: #0156FF; display: flex; align-items: center;" type="text" size="small"><i class="iconfont icon-bofang"></i>{{ $t('view') }}</el-button>
                  </template>
                </video-button>
              </span> -->
              <!-- <el-tooltip
                :content="$t('issueManager.playResult')"
                v-if="scope.row.status == '2'"
              >
                <el-button
                  style="color: #0156FF;"
                  type="text"
                  size="small"
                  @click="playResult(scope.row)"
                ><i class="iconfont icon-a-xialajiantoubeifen2"></i>{{ $t('issueManager.result') }}</el-button>
              </el-tooltip> -->
              <div
                v-if="scope.row.status == '1'&& scope.row.socialPostStatus!='delete'"
                class="flex-row-center list-button"
                @click="handleCancel(scope.row)"
              >
                <i class="iconfont icon-a-xialajiantoubeifen2"></i>
                {{ $t('issueManager.cancelPublish') }}
              </div>
              <div
                v-if="scope.row.status == '2'&& scope.row.socialPostStatus!='delete'"
                class="flex-row-center list-button"
                @click="handleBackout(scope.row)"
              >
                <i class="iconfont icon-a-xialajiantoubeifen2"></i>
                {{ $t('issueManager.unpublish') }}
              </div>
              <div
                class="flex-row-center list-button"
                @click="handleDelete(scope.row)"
              >
                <i class="iconfont icon-delete"></i>
                {{ $t('remove') }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="110px"
          prop="publishTimeType"
          :label="$t('issueManager.type')"
          align="center"
        >
          <template slot-scope="scope">
            <dict-tag
              :style="scope.row.isHoverCell ? 'opacity: 0' : 'opacity: 1'"
              :options="dict.type.publish_time_type"
              :value="scope.row.publishTimeType"
            />

          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          :label="$t('issueManager.status')"
          align="center"
          min-width="270"
        >
          <template slot-scope="scope">
            <div
              :style="scope.row.isHoverCell ? 'opacity: 0' : 'opacity: 1'"
              class="status"
              :class="'status-' + scope.row.status"
            >
              <span v-if="scope.row.status == 0">{{$t('issueManager.statusDraft')}}</span>
              <span v-if="scope.row.status == 1">
                <i class="iconfont icon-dacha1"></i>
                {{ $t('issueManager.publishing') }}
              </span>
              <span v-if="scope.row.status == 2">
                <i class="iconfont icon-a-dacha3"></i>
                {{ $t('issueManager.published') }}
              </span>
              <div
                v-if="scope.row.status == 3"
                class="hidden flex-column-center"
              >
                <div class="flex-center">
                  <i class="iconfont icon-a-dacha2"></i>
                  {{ $t('issueManager.failed') }}
                </div>
                <!-- <el-tooltip
                  v-if="scope.row.errorMessage && scope.row.errorMessage.length > 30"
                  :content="scope.row.errorMessage"
                  placement="top"
                >
                  <span class="line-2 pointer status-des">{{ scope.row.errorMessage }}</span>
                </el-tooltip> -->
                <!-- v-else -->
                <span class="status-des">{{ scope.row.errorMessage }}</span>
              </div>
              <!-- <el-tooltip :content="scope.row.errorMessage">
                <span
                  v-if="scope.row.status == 3"
                  style="color: #E34D59;cursor: pointer;"
                ><i class="iconfont icon-a-dacha2"></i>{{ $t('issueManager.failed') }}</span>
              </el-tooltip> -->
              <!-- <i v-if="scope.row.status == 2" class="el-icon-circle-check" style="color: #00A0FF;"></i> -->
              <!-- <i v-if="scope.row.status == 3" class="el-icon-circle-close" style="color: red;"></i> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          :label="$t('time.createTime')"
          align="center"
          prop="createTime"
          width="150"
        >
          <template slot-scope="scope">
            <span :style="scope.row.isHoverCell ? 'opacity: 0' : 'opacity: 1'">{{ scope.row.createTime }}</span>

          </template>
        </el-table-column>
        <el-table-column
          sortable
          :label="$t('issueManager.time')"
          align="center"
          prop="publishTime"
          width="150"
        >
          <template slot-scope="scope">
            <span :style="scope.row.isHoverCell ? 'opacity: 0' : 'opacity: 1'">{{ scope.row.publishTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="taskName"
          :label="$t('issueManager.taskName')"
        >
          <template slot-scope="scope">
            <span :style="scope.row.isHoverCell ? 'opacity: 0' : 'opacity: 1'">{{ scope.row.taskName }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :label="$t('operate')"
          min-width="150px"
        >
          <template slot-scope="scope">
            <div class="flex-row-center f-wrap">
              <el-button
                v-if="scope.row.status == '0'"
                icon="iconfont icon-edit"
                type="text"
                size="small"
                style="color: #0156FF;"
                @click="editVideo(scope.row,'edit')"
              >{{ $t('edit') }}</el-button>
              <el-button
                v-else
                icon="iconfont icon-shuaxin"
                type="text"
                size="small"
                style="color: #0156FF;"
                @click="editVideo(scope.row,'add')"
              >{{ $t('issueManager.republish') }}</el-button>
              <el-button
                v-if="scope.row.status == '1'&& scope.row.socialPostStatus!='delete'"
                style="color: #0156FF;"
                type="text"
                size="small"
                @click="handleCancel(scope.row)"
              ><i class="iconfont icon-a-xialajiantoubeifen2"></i>{{ $t('issueManager.cancelPublish') }}</el-button>
              <el-button
                v-if="scope.row.status == '2'&& scope.row.socialPostStatus!='delete'"
                style="color: #0156FF;"
                type="text"
                size="small"
                @click="handleBackout(scope.row)"
              ><i class="iconfont icon-a-xialajiantoubeifen2"></i>{{ $t('issueManager.unpublish') }}</el-button>
              <el-button
                style="color: #0156FF;"
                type="text"
                size="small"
                @click="handleDelete(scope.row)"
              ><i class="iconfont icon-delete"></i>{{ $t('remove') }}</el-button>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script>
import { listProfile } from "../../api/account/profile";
import { listAccount } from "../../api/account/tiktok";
import { deletePost, deletePublish, listPublish } from "../../api/ai/publish";
import { getQuota } from "../../api/system/merchant";
import VideoButton from "../../components/VideoButton/VideoButton.vue";
import Slider from "../account/slider.vue";

export default {
  // name:"IssueManager",//为了不缓存 注掉
  dicts: ["social_platform", "issue_status", "publish_time_type"],
  components: {
    VideoButton,
    Slider,
  },
  data() {
    return {
      loading: false,
      showSearch: true,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        socialType: "",
        username: "",
        publishTimeType: "",
        status: "",
        taskType: "",
        dateRange: "",
        statusList: [],
      },
      // 总条数
      total: 0,
      videos: [],
      accountList: [],
      videoElement: null, // 添加一个变量用于保存视频元素
      quota: {}, //配额
      isViewVideo: false,
      filterAccountList: [],
      tabList: [
        {
          label: this.$t("issueManager.allTasks"),
          iconFont: "icon-quanbu",
          isActive: true,
          statusList: [],
        },
        {
          label: this.$t("issueManager.published"),
          iconFont: "icon-telegram-normal",
          isActive: false,
          statusList: [2],
        },
        {
          label: this.$t("issueManager.drafts"),
          iconFont: "icon-a-dagoubeifen23",
          isActive: false,
          statusList: [0],
        },
      ],
      profileFilters: [],
      timer: null, //定时器——生成中的状态更新
    };
  },
  computed: {
    socialPlatformFilters() {
      return this.dict.type.social_platform.map((type) => ({
        text: type.label,
        value: type.value,
      }));
    },
  },
  mounted() {
    this.init();
    this.getProfileList();
  },
  beforeDestroy() {
    // 页面销毁前清除定时器
    this.clearTimer();
  },
  methods: {
    // 设置固定行高
    setRowStyle() {
      return { height: "80px" };
    },
    formattedTime(time) {
      if (!time) {
        return ""; // 如果时间为空，则返回空字符串
      }
      return time.split(" ").join("<br>"); // 空格分隔并换行
    },
    getProfileList() {
      listProfile().then((response) => {
        this.profile = response.rows;
        this.profileFilters = response.rows
          .filter(
            (type) =>
              type.activeSocialAccounts && type.activeSocialAccounts !== "[]"
          )
          .map((type) => ({
            text: type.topHeader,
            value: type.topHeader,
          }));
      });
    },
    filterChange(filter) {
      if (filter["socialType"]) {
        this.queryParams.socialType = filter.socialType.join(",");
        this.handleQuery();
      } else if (filter["account"]) {
        this.queryParams.account = filter.account.join(",");
        this.handleQuery();
      } else if (filter["topHeader"]) {
        this.queryParams.topHeader = filter.topHeader.join(",");
        this.handleQuery();
      }
    },
    clickSearchTable(item) {
      this.tabList.forEach((tabItem) => {
        if (item.label === tabItem.label) {
          this.queryParams.statusList = item.statusList;
          tabItem.isActive = true;
          this.handleQuery();
        } else {
          tabItem.isActive = false;
        }
      });
    },
    closeViewVideo() {
      this.isViewVideo = false;
    },
    viewVideo(row) {
      this.isViewVideo = true;
    },
    cellMouseLeave(row, column, cell, event) {
      if (this.isViewVideo) return;
      row.isHoverCell = false;
    },
    cellMouseEnter(row, column, cell, event) {
      row.isHoverCell = true;
    },
    init() {
      this.getQuota();
      this.getListAccount();
      this.getList();
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    //获取配额
    getQuota() {
      getQuota().then((response) => {
        this.quota = response.data;
      });
    },
    getListAccount() {
      listAccount()
        .then((response) => {
          this.accountList = response.rows;
          this.filterAccountList = response.rows.map((type) => ({
            text: type.platform == "facebook" ? type.pageName : type.username,
            value: type.platform == "facebook" ? type.pageName : type.username,
          }));
        })
        .catch(() => {});
    },
    getAccount(id) {
      const account = this.accountList.find((account) => account.id === id);
      return account ? account.username : "--";
    },
    getList() {
      this.loading = true;
      listPublish(this.queryParams)
        .then((response) => {
          this.loading = false;
          const newList = response.rows.map((item) => {
            return { ...item, isHoverCell: false };
          });
          this.videos = newList;
          this.total = response.total;
          // 检查是否存在 发布中 的项
          if (this.videos.some((item) => item.status == 1)) {
            // 如果定时器不存在，启动定时器
            if (!this.timer) {
              this.timer = setInterval(() => {
                this.getList();
              }, 30000); // 每30秒调用一次
            }
          } else {
            this.clearTimer();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    resetQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        socialType: "",
        username: "",
        publishTimeType: "",
        status: "",
        taskType: "",
        dateRange: "",
      };
      this.getList();
    },
    editVideo(row, type) {
      this.$router.push({
        path: `/issueVideo/${row.id}`,
        query: { isId: true, type: type },
      });
    },
    //查看结果
    playResult(row) {
      this.$router.push({
        path: "/infoStream",
        query: { id: row.id, socialId: row.socialId },
      });
    },
    // 取消发布
    handleCancel(row) {
      this.$modal
        .confirm(this.$t("issueManager.confirmCancelPublish"))
        .then(function () {
          return deletePost({ id: row.id });
        })
        .then(() => {
          this.getList();
          this.$modal.msgSuccess(this.$t("issueManager.cancelSuccess"));
        })
        .catch(() => {});
    },
    // 下架
    handleBackout(row) {
      this.$modal
        .confirm(this.$t("issueManager.confirmUnpublish"))
        .then(function () {
          return deletePost({ id: row.id });
        })
        .then(() => {
          this.getList();
          this.$modal.msgSuccess(this.$t("issueManager.unpublishSuccess"));
        })
        .catch(() => {});
    },
    // 删除
    handleDelete(row) {
      let message = this.$t("issueManager.confirmDelete"); // 默认提示信息
      let confirmText = this.$t("remove"); // 默认确认按钮文本
      let cancelText = this.$t("cancel"); // 默认取消按钮文本
      let params = { id: row.id }; // 默认删除请求的参数

      // 根据不同状态设置提示信息和请求参数
      if (row.status === 1) {
        message = this.$t("issueManager.confirmDeleteWhilePublishing");
        confirmText = this.$t("issueManager.cancelAndDelete");
        cancelText = this.$t("issueManager.deleteOnly");
        params.syncSocial = 1; // 需要同步删除社交平台上的内容
      } else if (row.status === 2) {
        message = this.$t("issueManager.confirmDeleteOnSocial");
        confirmText = this.$t("issueManager.unpublishAndDelete");
        cancelText = this.$t("issueManager.deleteOnly");
        params.syncSocial = 1; // 需要同步删除社交平台上的内容
      }

      // 统一调用 confirm 和 deletePublish
      this.$confirm(message, this.$t("systemPrompt"), {
        distinguishCancelAndClose: true,
        confirmButtonText: confirmText,
        cancelButtonText: cancelText,
      })
        .then(() => {
          deletePublish(params).then(() => {
            this.getList();
            this.$modal.msgSuccess(
              `${confirmText}${this.$t("issueManager.success")}`
            );
          });
        })
        .catch((action) => {
          // 如果用户点击取消，继续删除操作
          if (action === "cancel" && (row.status === 1 || row.status === 2)) {
            deletePublish({ id: row.id }).then(() => {
              this.getList();
              this.$modal.msgSuccess(this.$t("tip.remove"));
            });
          }
        });
    },
    // 创建视频
    createVideo() {
      if (
        this.isExceedQuota(this.quota.usePublishCount, this.quota.publishCount)
      ) {
        this.$modal.msgError(this.$t("material.exceedQuota"));
        return;
      }
      // 创建新视频逻辑
      this.$router.push({ path: "/issueVideo" });
    },
  },
};
</script>

<style lang="scss" scoped>
.video-manager {
  margin: 16px 24px;
  // border-radius: 10px;
  .leftActiveTab {
    display: flex;
    .tabItem {
      height: 22px;
      font-family: PingFang-SC, PingFang-SC;
      font-weight: bold;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.4);
      line-height: 22px;
      text-align: left;
      font-style: normal;
      margin-right: 32px;
      cursor: pointer;
    }
    .activeTab {
      background: linear-gradient(0deg, #0089ff 0%, #6e16d1 100%);
      background-clip: text; /* 无前缀标准写法 */
      -webkit-background-clip: text; /* 兼容 WebKit 浏览器 */
      -webkit-text-fill-color: transparent;
    }
  }
  .videoContent {
    // padding: 20px;
    background: #ffffff;
    border-radius: 14px;
    // border: 1px solid #e5e7eb;
  }

  .tableLeftVideo {
    display: flex;
    align-items: center;
    .cellVideo {
      width: 102px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #000;
      border-radius: 12px;
      position: relative;
      overflow: hidden;
      .cricle {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.6);
        cursor: pointer;
        .el-icon-caret-right {
          color: #fff;
          font-size: 18px;
        }
      }
      // opacity: 0.7;
    }
    .post-content {
      width: 140px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 22px;
      text-align: left;
      font-style: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-left: 15px;
    }
  }

  .noVideo {
    width: 102px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ecf2fe;
    border-radius: 12px;
    .flexBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: rgba(0, 0, 0, 0.3);
      .icon-xialajiantou1 {
        font-size: 25px;
      }
      .fontStyle {
        font-size: 12px;
      }
    }
  }
}
.post-content {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-clamp: 3;
}
.status {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  i {
    margin-right: 8px;
  }
}
.status-des {
  opacity: 0.8;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
}
.status-0 {
  color: rgba(0, 0, 0, 0.4);
}
.status-1 {
  color: #ed7b2f;
}
.status-2 {
  color: #00a870;
}
.status-3 {
  color: #e34d59;
}
/* 选中带有 filters 属性的表头，并添加自定义 iconfont 图标 */
::v-deep .el-table__column-filter-trigger i {
  font-family: "iconfont" !important;
  content: "\e631";
  font-style: normal !important;
}
/* 让新图标显示出来 */
::v-deep .el-table__column-filter-trigger i::before {
  font-family: "iconfont" !important;
  content: "\e631";
  font-size: 16px !important;
}
</style>
