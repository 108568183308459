var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "social-item flex-column" },
    [
      _c("div", { staticClass: "top flex-column" }, [
        _c(
          "div",
          { staticClass: "flex-row-center" },
          [
            _c("el-avatar", {
              staticStyle: { "margin-right": "8px", "flex-shrink": "0" },
              attrs: { src: _vm.avatarUrl, size: 48 },
            }),
            _c("div", [
              _c("div", { staticClass: "social-name" }, [
                _vm._v(" " + _vm._s(_vm.username)),
              ]),
              _c("div", { staticClass: "social-time" }, [
                _vm._v(" " + _vm._s(_vm.getTime())),
              ]),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "express" }, [
          _c(
            "div",
            {
              ref: "textElement",
              class: {
                hide: !_vm.isShow || !_vm.isExpandable,
                expand: _vm.isShow,
              },
            },
            [_vm._v(_vm._s(_vm.videoDescription))]
          ),
          !_vm.isShow && _vm.isExpandable
            ? _c("div", { staticClass: "tips", on: { click: _vm.showInfo } }, [
                _vm._v(_vm._s(_vm.$t("expansion"))),
              ])
            : _vm._e(),
          _vm.isShow
            ? _c(
                "div",
                { staticClass: "tips hide-btn", on: { click: _vm.showInfo } },
                [_vm._v(_vm._s(_vm.$t("collapse")))]
              )
            : _vm._e(),
        ]),
        _vm.item.videoUrl
          ? _c("video", {
              ref: "video",
              staticClass: "video",
              attrs: { src: _vm.getUrl(), controls: "" },
            })
          : _vm.platform === "tiktok"
          ? _c("iframe", {
              staticClass: "video",
              attrs: {
                src: _vm.getUrl(),
                frameborder: "0",
                allowfullscreen: "",
                height: "300px",
                width: "500px",
              },
            })
          : _c("div", { staticClass: "cellVideo" }, [
              _c("img", {
                staticStyle: { width: "100%", height: "100%" },
                attrs: { src: _vm.getUrl() },
              }),
              _c(
                "div",
                {
                  staticClass: "cricle",
                  on: {
                    click: function ($event) {
                      return _vm.openUrl(_vm.item.postUrl)
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-caret-right" })]
              ),
            ]),
        _c("div", { staticClass: "bottom flex-row-center f-between" }, [
          _c("div", { staticClass: "flex-column-center numBoxItem" }, [
            _c("span", { staticClass: "bf" }, [
              _vm._v(_vm._s(_vm.getViewNum() || 0)),
            ]),
            _c("span", { staticClass: "num" }, [
              _vm._v(" " + _vm._s(_vm.$t("publicationStream.plays"))),
            ]),
          ]),
          _c("div", { staticClass: "flex-column-center numBoxItem" }, [
            _c("span", { staticClass: "dz" }, [
              _vm._v(_vm._s(_vm.getLikeNum() || 0)),
            ]),
            _c("span", { staticClass: "num" }, [
              _vm._v(" " + _vm._s(_vm.$t("publicationStream.Likes"))),
            ]),
          ]),
          _c("div", { staticClass: "flex-column-center numBoxItem" }, [
            _c("span", { staticClass: "pl" }, [
              _vm._v(_vm._s(_vm.getCommentNum() || 0)),
            ]),
            _c("span", { staticClass: "num" }, [
              _vm._v(" " + _vm._s(_vm.$t("publicationStream.comments"))),
            ]),
          ]),
          _c("div", { staticClass: "flex-column-center numBoxItem" }, [
            _c("span", { staticClass: "zf" }, [
              _vm._v(_vm._s(_vm.getShareNum() || 0)),
            ]),
            _c("span", { staticClass: "num" }, [
              _vm._v(" " + _vm._s(_vm.$t("publicationStream.forwarding"))),
            ]),
          ]),
        ]),
      ]),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "comment-box flex-column-center" },
        [
          _c("Comment", {
            attrs: { socialId: _vm.socialId, postId: _vm.item.id },
          }),
          !_vm.showComment
            ? _c(
                "div",
                {
                  staticClass: "query-comment",
                  on: { click: _vm.queryComments },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("publicationStream.lookAllComments")) + " >"
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "500px" },
            },
            [
              _c("CommentList", {
                attrs: {
                  comments: _vm.commentList,
                  socialId: _vm.socialId,
                  postId: _vm.item.id,
                },
              }),
            ],
            1
          ),
          _vm.commentList.length > 0 && _vm.showComment
            ? _c(
                "div",
                {
                  staticClass: "query-comment",
                  on: {
                    click: function ($event) {
                      _vm.showComment = !_vm.showComment
                    },
                  },
                },
                [
                  _vm._v(_vm._s(_vm.$t("publicationStream.close")) + " "),
                  _c("i", { staticClass: "el-icon-arrow-up" }),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }