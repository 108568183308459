var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { position: "relative" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "120px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("issueItem.account"), prop: "socialIds" },
            },
            [
              _c("el-cascader", {
                staticStyle: { width: "100%" },
                attrs: {
                  "show-all-levels": false,
                  props: _vm.props,
                  options: _vm.newAccounts,
                },
                on: { change: _vm.updateSocialTimeMap },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var data = ref.data
                      return [
                        data.platform
                          ? _c("i", {
                              staticClass: "iconfont mr5 font20",
                              class: [
                                "icon-" + data.platform + "-normal",
                                data.platform,
                              ],
                            })
                          : _vm._e(),
                        _c("span", [_vm._v(_vm._s(data.label))]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.form.socialIds,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "socialIds", $$v)
                  },
                  expression: "form.socialIds",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 14 } },
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: {
                        label: _vm.$t("issueItem.post"),
                        prop: "postContent",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { border: "0px" },
                        attrs: {
                          id: "postContent" + _vm.index,
                          maxlength: "500",
                          "show-word-limit": "",
                          type: "textarea",
                          autosize: { minRows: 4, maxRows: 6 },
                          placeholder: _vm.$t("issueItem.tipPost"),
                        },
                        on: { input: _vm.changePost },
                        model: {
                          value: _vm.form.postContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "postContent", $$v)
                          },
                          expression: "form.postContent",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "flex-row-center mt5" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.importText },
                            },
                            [_vm._v(_vm._s(_vm.$t("basicInfo.materialImport")))]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.oneTranslate },
                            },
                            [_vm._v(_vm._s(_vm.$t("issueItem.oneTranslate")))]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.reset },
                            },
                            [_vm._v(_vm._s(_vm.$t("issueItem.reset")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex-row-center mt5" },
                        [
                          _c(
                            "el-tooltip",
                            { attrs: { content: _vm.$t("issueItem.emo") } },
                            [
                              _c("svg-icon", {
                                staticStyle: {
                                  width: "25px",
                                  height: "25px",
                                  "margin-top": "5px",
                                  "margin-left": "10px",
                                },
                                attrs: { "icon-class": "smile" },
                                on: {
                                  click: function ($event) {
                                    _vm.showEmoPicker = !_vm.showEmoPicker
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: { content: _vm.$t("issueItem.translate") },
                            },
                            [
                              _c("svg-icon", {
                                staticStyle: {
                                  width: "25px",
                                  height: "25px",
                                  "margin-left": "10px",
                                },
                                attrs: { "icon-class": "翻译" },
                                on: { click: _vm.chooseLanguage },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            { attrs: { content: _vm.$t("subtitle.sumup") } },
                            [
                              _c("svg-icon", {
                                staticStyle: {
                                  width: "25px",
                                  height: "25px",
                                  "margin-left": "10px",
                                },
                                attrs: { "icon-class": "ai_sum_up" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rewrite("sc")
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: _vm.$t("subtitle.abbreviation"),
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticStyle: {
                                  width: "25px",
                                  height: "25px",
                                  "margin-left": "10px",
                                },
                                attrs: { "icon-class": "ai_abbreviation" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rewrite("sx")
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            { attrs: { content: _vm.$t("subtitle.expand") } },
                            [
                              _c("svg-icon", {
                                staticStyle: {
                                  width: "25px",
                                  height: "25px",
                                  "margin-left": "10px",
                                },
                                attrs: { "icon-class": "ai_expand" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rewrite("kx")
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            { attrs: { content: _vm.$t("subtitle.rewrite") } },
                            [
                              _c("svg-icon", {
                                staticStyle: {
                                  width: "25px",
                                  height: "25px",
                                  "margin-left": "10px",
                                },
                                attrs: { "icon-class": "ai_rewrite" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rewrite("cx")
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("emo-picker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showEmoPicker,
                            expression: "showEmoPicker",
                          },
                        ],
                        attrs: {
                          inputId: "postContent" + _vm.index,
                          showFrequentlyUsedList: false,
                        },
                        on: {
                          select: _vm.emojiClick,
                          selectBack: _vm.updateContent,
                        },
                      }),
                      _vm.topics.length
                        ? _c(
                            "div",
                            _vm._l(_vm.topics, function (topic) {
                              return _c(
                                "el-tag",
                                {
                                  key: topic,
                                  staticStyle: { margin: "5px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTopic(topic)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(topic))]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.hasLanguageNames
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "120px",
                            color: "red",
                            "font-size": "12px",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("issueItem.tipOneTranslate")) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._l(_vm.form.socialIdsByTranslate, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticStyle: { "margin-left": "120px" } },
                      [
                        item.languageName
                          ? _c(
                              "div",
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(item.languageName) +
                                    "（" +
                                    _vm._s(item.accounts) +
                                    "） "
                                ),
                                _c("el-input", {
                                  attrs: { type: "textarea" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.updateTranslation(
                                        index,
                                        item.text
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.text,
                                    callback: function ($$v) {
                                      _vm.$set(item, "text", $$v)
                                    },
                                    expression: "item.text",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("issueItem.selectVideo"),
                        prop: "video",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "aspect-ratio-box-container" },
                        [
                          _c("uploadFile", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.video.length == 0,
                                expression: "form.video.length==0",
                              },
                            ],
                            on: {
                              selectMaterial: _vm.selectMaterial,
                              showLocaMedia: _vm.showLocaMedia,
                            },
                          }),
                          _vm._l(_vm.form.video, function (material, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "aspect-ratio-box flex-column",
                                on: {
                                  mouseover: function ($event) {
                                    material.hover = true
                                  },
                                  mouseleave: function ($event) {
                                    material.hover = false
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "material-container" },
                                  [
                                    _c("video", {
                                      staticClass: "material-thumbnail",
                                      attrs: { src: material.url },
                                    }),
                                    material.hover
                                      ? _c("div", { staticClass: "overlay" }, [
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.playVideo(
                                                    material.url
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("issueItem.paly"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticStyle: { margin: "5px" } },
                                            [_vm._v("|")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeMaterial(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("issueItem.remove")
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("issueItem.publishType"),
                prop: "timeType",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { input: _vm.clearPublishTime },
                  model: {
                    value: _vm.form.timeType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "timeType", $$v)
                    },
                    expression: "form.timeType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "0" } }, [
                    _vm._v(_vm._s(_vm.$t("issueItem.sameTime"))),
                  ]),
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v(_vm._s(_vm.$t("issueItem.customTime"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.timeType === "0"
            ? _c(
                "el-form-item",
                { attrs: { prop: "publishTimeType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { input: _vm.clearPublishTime },
                      model: {
                        value: _vm.form.publishTimeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "publishTimeType", $$v)
                        },
                        expression: "form.publishTimeType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v(_vm._s(_vm.$t("issueItem.issueNow"))),
                      ]),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v(_vm._s(_vm.$t("issueItem.issueCountry"))),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v(_vm._s(_vm.$t("issueItem.issueUnify"))),
                      ]),
                    ],
                    1
                  ),
                  _vm.form.publishTimeType === "1"
                    ? _c(
                        "div",
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "picker-options": _vm.pickerOptions,
                              type: "datetime",
                              placeholder: _vm.$t("issueItem.tipDate"),
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            on: { change: _vm.changePickerNormal },
                            model: {
                              value: _vm.form.publishTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "publishTime", $$v)
                              },
                              expression: "form.publishTime",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.publishTimeType === "1"
                    ? _c(
                        "div",
                        { staticStyle: { color: "red", "font-size": "12px" } },
                        [_vm._v(" " + _vm._s(_vm.$t("issueItem.tipSet")) + " ")]
                      )
                    : _vm._e(),
                  _vm.form.publishTimeType === "2"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t("issueItem.tipTimezone"),
                                filterable: "",
                              },
                              on: {
                                change: function ($event) {
                                  _vm.form.publishTime = ""
                                },
                              },
                              model: {
                                value: _vm.form.timeZoneId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "timeZoneId", $$v)
                                },
                                expression: "form.timeZoneId",
                              },
                            },
                            _vm._l(_vm.timeOptions, function (time) {
                              return _c("el-option", {
                                key: time.id,
                                staticClass: "ellipsis-option",
                                attrs: {
                                  label: time.rawFormat,
                                  value: time.id,
                                },
                              })
                            }),
                            1
                          ),
                          _c("el-date-picker", {
                            attrs: {
                              "picker-options": _vm.pickerOptions,
                              type: "datetime",
                              placeholder: _vm.$t("issueItem.tipDate"),
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              disabled:
                                _vm.form.timeType === "0" &&
                                _vm.form.publishTimeType === "2" &&
                                !_vm.form.timeZoneId,
                            },
                            on: { change: _vm.changePicker },
                            model: {
                              value: _vm.form.publishTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "publishTime", $$v)
                              },
                              expression: "form.publishTime",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.form.timeType === "1"
            ? _c(
                "el-form-item",
                { attrs: { prop: "socialTimeMap" } },
                [
                  _c("SocialTime", {
                    attrs: {
                      template: _vm.socialTimeMap,
                      timeOptions: _vm.timeOptions,
                    },
                    on: { refreshMap: _vm.refreshMap },
                  }),
                  !_vm.socialTimeMap ||
                  Object.keys(_vm.socialTimeMap).length === 0
                    ? _c(
                        "div",
                        { staticStyle: { color: "red", "font-size": "12px" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("issueItem.tipSocialTime")) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("i", {
        staticClass: "iconfont icon-delete",
        staticStyle: {
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          cursor: "pointer",
        },
        on: {
          click: function ($event) {
            return _vm.$emit("remove")
          },
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("issueItem.product"),
            size: "65%",
            visible: _vm.drawer,
            "with-header": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("generated-video", {
            ref: "generated",
            on: { selectionConfirmed: _vm.selectionConfirmed },
          }),
        ],
        1
      ),
      _c("material-drawer", {
        ref: "materialDrawer",
        attrs: { visible: _vm.drawerMaterial },
        on: {
          "update:visible": function ($event) {
            _vm.drawerMaterial = $event
          },
          selectionConfirmed: _vm.selectionText,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: _vm.$t("issueItem.translateTo"),
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: {
                placeholder: _vm.$t("issueItem.translateLanguage"),
                filterable: "",
                clearable: "",
              },
              model: {
                value: _vm.language,
                callback: function ($$v) {
                  _vm.language = $$v
                },
                expression: "language",
              },
            },
            _vm._l(_vm.languageList, function (dict) {
              return _c("el-option", {
                key: dict.code,
                attrs: { label: dict.name, value: dict.code },
              })
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v(_vm._s(_vm.$t("cancel"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogPlay,
            width: "50%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPlay = $event
            },
          },
        },
        [
          _c("video", {
            ref: "videoPlayer",
            staticClass: "video-player",
            attrs: { controls: "", src: _vm.playUrl },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }