var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100%", width: "100%" } }, [
    _c("div", { attrs: { id: "fb-root" } }),
    _c("div", {
      staticClass: "fb-video",
      attrs: {
        "data-href": _vm.videoUrl,
        "data-width": "500",
        "data-show-text": "false",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }