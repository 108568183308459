<template>
  <div class="video-page">
    <!-- 主容器 -->
    <div
      class="video-container flex-center"
      v-loading="loading"
    >
      <div
        v-if="videoList.length > 0"
        class="flex-center"
      >
        <!-- 视频播放区域 -->
        <div
          class="carousel-container"
          ref="carouselContainer"
        >
          <el-carousel
            height="800px"
            :autoplay="false"
            direction="vertical"
            indicator-position="none"
            @change="onSlideChange"
            ref="carousel"
          >
            <el-carousel-item
              v-for="(video, index) in videoList"
              :key="index"
            >
              <div class="flex-row">
                <div class="video-frame-container flex-column f-between">
                  <iframe
                    ref="iframeRefs"
                    v-if="isOverWall && currentVideoIndex === index"
                    :src="getVideoUrl(video)"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                  ></iframe>
                  <video
                    v-else-if="currentVideoIndex === index"
                    ref="videoRefs"
                    :src="video.url"
                    controls
                    autoplay
                    class="video-player-info"
                  ></video>
                  <!-- muted -->
                  <div class="bottom-content flex-row-center f-between f-shrink">
                    <el-avatar
                      :size="48"
                      :src="video.avatarUrl"
                      class="f-shrink"
                    ></el-avatar>
                    <span class="name ml10 f-grow single-line-ellipsis">{{ video.displayName }}</span>
                    <span class="time ml10 f-shrink">{{ video.publishTime|dateDiff }}</span>
                  </div>
                </div>
                <!-- 平台logo、播赞评转数量 -->
                <div class="video-nums flex-column-center f-between">
                  <div class="icon-container flex-center">
                    <i
                      class="iconfont socialType-icon"
                      :class="['icon-' + video.socialType, video.socialType]"
                    ></i>
                  </div>
                  <div>
                    <div class="flex-column-center mt10">
                      <div class="video-nums-circle flex-center"><i class="iconfont icon-font icon-bofang1"></i> </div>
                      <span class="video-nums-text">{{ video.views && video.views > 0 ? video.views : 0 }}</span>
                    </div>
                    <div class="flex-column-center mt10">
                      <div class="video-nums-circle flex-center"><i class="iconfont icon-font icon-dianzan"></i> </div>
                      <span class="video-nums-text">{{ video.likes && video.likes > 0 ? video.likes : 0 }}</span>
                    </div>
                    <div
                      class="flex-column-center mt10 pointer"
                      @click="showComment"
                    >
                      <div class="video-nums-circle flex-center"><i class="iconfont icon-font icon-pinglun"></i> </div>
                      <span class="video-nums-text">{{ video.comments && video.comments > 0 ? video.comments : 0 }}</span>
                    </div>
                    <div class="flex-column-center mt10">
                      <div class="video-nums-circle flex-center"><i class="iconfont icon-font icon-zhuanfa"></i> </div>
                      <span class="video-nums-text">{{ video.shares && video.shares > 0 ? video.shares : 0 }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <!-- 评论 -->
        <div
          v-if="isShowComment"
          ref="scrollableDiv"
          class="video-frame-container video-comment flex-column"
        >
          <div class="comment-head flex-row-center">
            {{ $t('publicationStream.commentContent') }}
            <div class="comment-head-num">{{ commentNum || currentVideo.comments || 0 }}</div>
            <i
              class="el-icon-close comment-head-close"
              @click="isShowComment = false"
            ></i>
          </div>
          <div class="comment-bottom flex-column">

            <div
              class="f-grow"
              v-loading="commentLoading"
              style="overflow-y: auto;"
            >
              <div v-if="commentList.length>0">
                <CommentList
                  :comments="commentList"
                  :postId="currentVideo.id"
                  :socialAccounts="getCurrentAccounts"
                  @commentSuccess="commentSuccess"
                />
              </div>
              <el-empty
                v-if="!commentLoading && commentList.length === 0"
                :description="$t('publicationStream.noComment')"
              ></el-empty>
            </div>
            <Comment
              v-if="socialAccounts.length > 0 && getCurrentAccounts.length > 0"
              :socialAccounts="getCurrentAccounts"
              :postId="currentVideo.id"
              @commentSuccess="commentSuccess"
            />
          </div>
        </div>
        <!-- 右侧按钮 -->
        <div class="video-controls">
          <div
            class="arrowButton flex-center"
            @click="playPreviousVideo"
            v-if="currentVideoIndex!==0"
          >
            <i class="el-icon-arrow-up icon"></i>
          </div>
          <div
            style="width: 58px; height: 58px;"
            v-if="currentVideoIndex==0"
          ></div>
          <div
            class="arrowButton flex-center"
            @click="playNextVideo"
            v-if="videoList.length > 1"
          >
            <!-- 正常数据 && currentVideoIndex !== videoList.length-1 -->
            <i class="el-icon-arrow-down icon"></i>
          </div>
        </div>
      </div>
      <el-empty
        :description="$t('publicationStream.noData')"
        :image-size="200"
        :image="require('@/assets/logo/logo.png')"
        v-if="!loading && videoList.length === 0"
      >
        <el-button
          class="gradient-button"
          @click="goPublish"
        >{{ $t('publicationStream.goPublish') }}</el-button>
      </el-empty>
    </div>
  </div>
</template>
<script>
import { queryComments, queryInformationFlow } from "../../api/ai/publish";
import FacebookVideo from "./components/FacebookVideo.vue";
import CommentList from "../../components/Comment/CommentList.vue";
import Comment from "../../components/Comment/Comment.vue";
import { listAllAccount } from "../../api/account/tiktok";
export default {
  components: {
    FacebookVideo,
    CommentList,
    Comment,
  },
  data() {
    return {
      currentVideoIndex: 0,
      previousVideoIndex: null, // 跟踪上一个视频的索引
      loading: false,
      videoList: [], //视频列表
      scrolling: false, // 防止快速多次触发的状态变量
      socialAccounts: [], // 社交账号列表
      isShowComment: false, // 是否显示评论
      commentLoading: false, // 评论加载状态
      commentList: [], //评论列表
      commentNum: 0, //当前评论数量
      // scrolling: false,
      touchStartY: 0, // 记录触摸起点的 Y 坐标
      checkInterval: null, // 定时器
      isOverWall: false, // 是否翻墙
    };
  },
  computed: {
    currentVideo() {
      // 确保索引有效（循环逻辑）
      const currentIndex = this.currentVideoIndex % this.videoList.length;
      const currentVideo = this.videoList[currentIndex] || {};
      this.commentNum = currentVideo.comments;
      return currentVideo;
    },
    nextVideo() {
      const nextIndex = (this.currentVideoIndex + 1) % this.videoList.length;
      return this.videoList[nextIndex] || {};
    },
    getCurrentAccounts() {
      if (!this.socialAccounts || this.socialAccounts.length === 0) {
        return [];
      }
      const a = this.socialAccounts.filter(
        (item) => item.platform === this.currentVideo.socialType
      );
      // 过滤符合条件的平台
      console.log("socialAccounts不为空", a);
      return this.socialAccounts.filter(
        (item) => item.platform === this.currentVideo.socialType
      );
    },
  },
  mounted() {
    this.getSocialAccountList();
    this.startCheckInterval(); // 组件挂载时启动定时器
    this.getList();
    // 添加鼠标滚动监听
    window.addEventListener(
      "wheel",
      this.throttle(this.handleMouseScroll, 150, true)
    );
    // window.addEventListener("wheel", this.handleMouseScroll);

    if ("ontouchstart" in window || navigator.maxTouchPoints > 0) {
      console.log("Touch events supported");
    } else {
      console.log("Touch events not supported");
    }
  },
  beforeDestroy() {
    this.stopCheckInterval(); // 组件销毁时清除定时器
    // 移除鼠标滚动监听
    window.removeEventListener(
      "wheel",
      this.throttle(this.handleMouseScroll, 150, true)
    );
    // 移除触控滑动监听
  },
  methods: {
    startCheckInterval() {
      this.checkIfBlocked(); // 立即执行一次
      this.checkInterval = setInterval(() => {
        this.checkIfBlocked();
      }, 30000); // 每30秒执行一次
    },
    stopCheckInterval() {
      if (this.checkInterval) {
        clearInterval(this.checkInterval); // 清除定时器
        this.checkInterval = null;
      }
    },
    // 滑动切换时触发
    onSlideChange(index) {
      this.currentVideoIndex = index;
    },
    commentSuccess(data) {
      // this.commentList.unshift(data);
      this.queryComments();
    },
    // 点击评论按钮
    showComment() {
      this.isShowComment = true;
      this.queryComments();
    },
    // 获取评论列表
    queryComments() {
      this.commentLoading = true;
      queryComments({
        publishId: this.currentVideo.id,
      })
        .then((res) => {
          this.commentList = res.rows;
          this.commentNum = res.total;
          this.currentVideo.comments = res.total;
        })
        .finally(() => {
          this.commentLoading = false;
        });
    },
    // 获取账号列表
    getSocialAccountList() {
      listAllAccount().then((res) => {
        const newList = res.data.map((item) => {
          return { ...item, isSelected: false };
        });
        this.socialAccounts = newList;
      });
    },
    async checkIfBlocked() {
      // try {
      //   const response = await fetch("https://www.google.com", {
      //     method: "HEAD",
      //     mode: "no-cors",
      //   });
      //   this.isOverWall = true; // 能访问，已翻墙
      // } catch (error) {
      //   this.isOverWall = false; // 请求失败，未翻墙
      // }
      try {
        const response = await fetch(
          "https://dns.google/resolve?name=www.google.com",
          {
            method: "GET",
            cache: "no-store", // 禁用缓存
          }
        );
        const data = await response.json();
        if (data && data.Status === 0) {
          this.isOverWall = true;
        } else {
          this.isOverWall = false;
        }
      } catch (error) {
        this.isOverWall = false;
      }
    },
    goPublish() {
      this.$router.push({ path: "/issueManager" });
    },
    getVideoUrl(item) {
      if (!item.externalPostUrl) return "";
      const videoId = item.externalPostUrl.split("/").pop();
      if (item.socialType === "tiktok") {
        return `https://www.tiktok.com/player/v1/${videoId}?autoplay=1&mute=0&loop=1&music_info=0&description=0`;
      } else if (item.socialType === "youtube") {
        return `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=0&controls=1&rel=0&showinfo=0&loop=1&playlist=${videoId}`;
      } else if (item.socialType === "twitter") {
        return `https://twitframe.com/show?url=${item.externalPostUrl}&hide_thread=true`;
      } else if (item.socialType === "instagram") {
        return `https://www.instagram.com/p/${videoId}/embed/?hidecaption=true`;
      } else if (item.socialType === "facebook") {
        return `https://www.facebook.com/plugins/video.php?href=${item.externalPostUrl}&mute=1&autoplay=1&show_text=false`;
      } else return item.externalPostUrl;
    },
    getList() {
      this.loading = true;
      // 发布列表，取发布状态是成功的，且externalPostUrl有值的
      queryInformationFlow({ showPub: true })
        .then((response) => {
          this.videoList = response.rows;
          // .filter(
          //   (item) => item.status === 2 && item.externalPostUrl
          // ); // 筛选数据
        })
        .finally(() => {
          this.loading = false;
        });
    },
    scrollToTop() {
      this.$refs.videoFrame.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    playNextVideo() {
      this.isShowComment = false;
      this.previousVideoIndex = this.currentVideoIndex;
      // 循环切换到下一个视频
      this.currentVideoIndex =
        (this.currentVideoIndex + 1) % this.videoList.length;
      this.$refs.carousel.setActiveItem(this.currentVideoIndex);
      this.handleVideoPlayback();
    },
    playPreviousVideo() {
      this.isShowComment = false;
      this.previousVideoIndex = this.currentVideoIndex;
      // 循环切换到上一个视频
      this.currentVideoIndex =
        (this.currentVideoIndex - 1 + this.videoList.length) %
        this.videoList.length;
      this.$refs.carousel.setActiveItem(this.currentVideoIndex);
      this.handleVideoPlayback();
    },
    // 控制视频播放逻辑
    handleVideoPlayback() {
      // 停止上一个视频的播放
      if (this.previousVideoIndex !== null) {
        if (this.isOverWall) {
          const iframe = this.$refs.iframeRefs[this.previousVideoIndex];
          if (iframe) {
            iframe.src = ""; // 清空 src 以停止播放
          }
        } else {
          // 如果是 video 元素
          if (this.$refs.videoRefs[this.previousVideoIndex]) {
            this.$refs.videoRefs[this.previousVideoIndex].pause();
          }
        }
      }

      // // 自动播放当前视频
      // if (this.$refs.videoRefs[this.currentVideoIndex]) {
      //   this.$refs.videoRefs[this.currentVideoIndex].play();
      // }
      // 播放当前视频
      if (this.currentVideoIndex !== null) {
        if (this.isOverWall) {
          // 如果是 iframe 元素
          const iframe = this.$refs.iframeRefs[this.currentVideoIndex];
          if (iframe) {
            iframe.src = this.getVideoUrl(
              this.videoList[this.currentVideoIndex]
            ); // 设置新的 src
          }
        } else {
          // 如果是 video 元素
          if (this.$refs.videoRefs[this.currentVideoIndex]) {
            this.$refs.videoRefs[this.currentVideoIndex].play();
          }
        }
      }
    },
    handleMouseScroll(event) {
      // debugger
      // 获取目标 div 元素
      // const scrollableDiv = this.$refs.carouselContainer;

      // 判断滚动事件是否发生在目标 div 内
      // if (scrollableDiv && scrollableDiv.contains(event.target)) {
      // 如果是目标 div 内部，直接返回，避免执行滚动操作
      // return;
      // }
      // console.log(scrollableDiv);

      // if (this.scrolling) return; // 防抖处理，正在滚动时直接返回
      // this.scrolling = true; // 设置正在滚动的标志
      if (event.deltaY > 0) {
        this.playNextVideo();
      } else {
        this.playPreviousVideo();
      }
      // 延迟解除防抖状态
      // setTimeout(() => {
      //   this.scrolling = false;
      // }, 300); // 设置滚动间隔为 300ms，可根据需要调整
    },
    throttle(func, delay, immediate) {
      var timer = null;
      return function () {
        var context = this;
        var args = arguments;
        if (timer) clearTimeout(timer);
        if (immediate) {
          var doNow = !timer;
          timer = setTimeout(function () {
            timer = null;
          }, delay);
          if (doNow) {
            func.apply(context, args);
          }
        } else {
          timer = setTimeout(function () {
            func.apply(context, args);
          }, delay);
        }
      };
    },
    // handleTouchStart(event) {
    //   console.log("handleTouchStart", event);
    //   // 记录触摸起点
    //   this.touchStartY = event.touches[0].clientY;
    // },
    // handleTouchMove(event) {
    //   console.log("handleTouchMove", event);
    //   const scrollableDiv = this.$refs.scrollableDiv;

    //   if (scrollableDiv && scrollableDiv.contains(event.target)) {
    //     return;
    //   }
    //   if (this.scrolling) return; // 防抖处理

    //   const touchEndY = event.touches[0].clientY;
    //   const deltaY = touchEndY - this.touchStartY; // 计算滑动的距离和方向
    //   if (Math.abs(deltaY) > 30) {
    //     // 设置滑动的阈值，避免误触
    //     this.scrolling = true;

    //     if (deltaY < 0) {
    //       this.playNextVideo(); // 向上滑动
    //     } else {
    //       this.playPreviousVideo(); // 向下滑动
    //     }

    //     setTimeout(() => {
    //       this.scrolling = false;
    //     }, 300);
    //   }
    // },
  },
};
</script>
<style scoped lang="scss">
.video-page {
  // padding: 15px;
  margin: 15px;
  background: #fff;
  // width: 100%;
  height: calc(100vh - 90px);
  overflow: hidden;
  border-radius: 10px;
}

.video-container {
  width: 100%;
  height: calc(100vh - 120px);
  background: #fafcfd;
  border-radius: 10px;
  box-sizing: border-box;
  // overflow: hidden;
}
.carousel-container {
  height: calc(100vh - 136px); /* 固定高度为90% */
  width: calc((100vh - 136px) * 9 / 16);
  overflow: hidden;
  // margin: 8px 0 16px;

  // overflow-y: auto; // 垂直方向可滚动
  // -webkit-overflow-scrolling: touch; // 优化移动端滚动体验
  // position: relative; // 确保内部元素定位正确
}

.video-frame-container {
  height: calc(100vh - 136px); /* 固定高度为90% */
  width: calc((100vh - 136px) * 9 / 16);
  overflow: hidden;
  margin: 0;

  // overflow-y: auto; // 垂直方向可滚动
  // -webkit-overflow-scrolling: touch; // 优化移动端滚动体验
  // position: relative; // 确保内部元素定位正确
}
.video-player-info {
  width: 100%;
  height: calc(100vh - 200px);
  border: none;
  border-radius: 10px;
  overflow: hidden; /* 隐藏滚动条 */
  display: block;
  scrollbar-width: none;
  background: #000;
}

.video-frame-container iframe {
  /* position: absolute; */
  /* top: 0;
  left: 0; */
  width: 100%;
  height: calc(100vh - 200px);
  border: none;
  border-radius: 10px;
  overflow: hidden; /* 隐藏滚动条 */
  display: block;
  scrollbar-width: none;
  border: 1px solid #ccc;
}
iframe[src*="facebook.com"] {
  background: #000;
}
.bottom-content {
  width: 100%;
  height: 80px;
  padding: 0 16px;
  z-index: 10;

  .name {
    height: 20px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 20px;
    text-align: left;
    font-style: normal;
  }
  .time {
    height: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 18px;
    text-align: left;
    font-style: normal;
  }
}

.video-controls {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.arrowButton {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: rgba(1, 86, 255, 0.1);
  cursor: pointer;

  .icon {
    font-size: 24px;
    color: #0156ff;
  }
}
.arrowButton:hover {
  background: rgba(1, 86, 255, 0.2);
}

.video-nums {
  width: 48px;
  margin-left: 24px;
  height: calc(100vh - 340px);
  align-self: flex-start;
  padding-top: 8px;

  .icon-container {
    align-self: flex-start;
    width: 48px;
    height: 48px;
    border-radius: 24px; /* 圆角 */
    overflow: hidden; /* 确保内容裁剪 */
    box-sizing: border-box; /* 防止 padding 影响尺寸 */

    .socialType-icon {
      font-size: 48px;
    }
  }
  .video-nums-circle {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background: #f4f4f4;

    .icon-font {
      font-size: 28px;
      color: #0f0f0f;
    }
  }
  .video-nums-text {
    height: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 20px;
    font-style: normal;
  }
}
.video-comment {
  margin-left: 100px;
  background: white;
  border-radius: 20px;
  align-self: flex-start;
  border: 1px solid #dcdcdc;

  .comment-head {
    height: 48px;
    border-bottom: 1px solid #dcdcdc;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 22px;
    font-style: normal;
    padding: 0 24px;

    .comment-head-num {
      margin-left: 24px;
      flex-grow: 1;
    }
    .comment-head-close {
      font-size: 16px;
    }
  }
  .comment-bottom {
    height: calc(100% - 48px);
    padding: 16px 24px;
    flex-grow: 1;
  }
}

@media screen and (max-height: 900px) {
  .video-nums {
    width: 40px;
    margin-left: 24px;
    height: calc(100vh - 320px);
    align-self: flex-start;
    padding-top: 8px;

    .icon-container {
      align-self: flex-start;
      width: 40px;
      height: 40px;
      border-radius: 50%; /* 圆角 */
      overflow: hidden; /* 确保内容裁剪 */
      box-sizing: border-box; /* 防止 padding 影响尺寸 */

      .socialType-icon {
        font-size: 40px;
      }
    }
    .video-nums-circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #f4f4f4;

      .icon-font {
        font-size: 23px;
        color: #0f0f0f;
      }
    }
    .video-nums-text {
      height: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 18px;
      font-style: normal;
    }
  }
}
@media screen and (max-height: 768px) {
  .video-nums {
    width: 32px;
    margin-left: 24px;
    height: calc(100vh - 320px);
    align-self: flex-start;
    padding-top: 8px;

    .icon-container {
      align-self: flex-start;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden; /* 确保内容裁剪 */
      box-sizing: border-box; /* 防止 padding 影响尺寸 */

      .socialType-icon {
        font-size: 32px;
      }
    }
    .video-nums-circle {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #f4f4f4;

      .icon-font {
        font-size: 18px;
        color: #0f0f0f;
      }
    }
    .video-nums-text {
      height: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 16px;
      font-style: normal;
    }
  }
}
</style>
