<template>
  <el-card style="position: relative;">
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <!-- 选择发布账号 -->
      <el-form-item :label="$t('issueItem.account')" prop="socialIds">
        <el-cascader
          style="width: 100%;"
          :show-all-levels="false"
          :props="props"
          v-model="form.socialIds"
          :options="newAccounts"
          @change="updateSocialTimeMap"
        >
          <template slot-scope="{ data }">
            <i
              v-if="data.platform"
              class="iconfont mr5 font20"
              :class="['icon-' + data.platform  + '-normal', data.platform]"
            ></i>
            <span>{{ data.label }}</span>
          </template>
          <template slot="default" slot-scope="{ data }">
            <i
              v-if="data.platform"
              class="iconfont mr5 font20"
              :class="['icon-' + data.platform  + '-normal', data.platform]"
            ></i>
            <span>{{ data.label }}</span>
          </template>

        </el-cascader>
        <!-- <el-select v-model="form.socialIds" filterable multiple  :placeholder="$t('issueItem.tipAccount')">
          <el-option v-for="account in accountList" :key="account.id" :label="getAccount(account)" :value="account.id"></el-option>
        </el-select> -->
      </el-form-item>
      <el-row>
          <el-col :span="14">
           <!-- 文案编辑 -->
            <el-form-item :label="$t('issueItem.post')" prop="postContent" v-loading="loading">
              <el-input
                :id="'postContent'+index"
                maxlength="500" show-word-limit
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6}"
                v-model="form.postContent"
                :placeholder="$t('issueItem.tipPost')"
                style="border: 0px;"
                @input="changePost"
              >
              <!-- @input="searchTopic" -->
              </el-input>
              <div class="flex-row-center mt5">
                <el-button size="small" @click="importText">{{$t('basicInfo.materialImport')}}</el-button>
                <el-button size="small" @click="oneTranslate">{{$t('issueItem.oneTranslate')}}</el-button>
                <el-button size="small" @click="reset">{{$t('issueItem.reset')}}</el-button>
              </div>
              <div class="flex-row-center mt5">
                <el-tooltip :content="$t('issueItem.emo')">
                  <svg-icon style="width: 25px;height: 25px;margin-top: 5px;margin-left: 10px;" icon-class="smile" @click="showEmoPicker=!showEmoPicker"/>
                </el-tooltip>
                <el-tooltip :content="$t('issueItem.translate')">
                  <svg-icon icon-class="翻译" style="width: 25px;height: 25px;margin-left: 10px;" @click="chooseLanguage"/>
                </el-tooltip>
                <el-tooltip :content="$t('subtitle.sumup')">
                  <svg-icon icon-class="ai_sum_up" style="width: 25px;height: 25px;margin-left: 10px;" @click="rewrite('sc')"/>
                </el-tooltip>
                <el-tooltip :content="$t('subtitle.abbreviation')">
                  <svg-icon icon-class="ai_abbreviation" style="width: 25px;height: 25px;margin-left: 10px;" @click="rewrite('sx')"/>
                </el-tooltip>
                <el-tooltip :content="$t('subtitle.expand')">
                  <svg-icon icon-class="ai_expand" style="width: 25px;height: 25px;margin-left: 10px;" @click="rewrite('kx')"/>
                </el-tooltip>
                <el-tooltip :content="$t('subtitle.rewrite')">
                  <svg-icon icon-class="ai_rewrite" style="width: 25px;height: 25px;margin-left: 10px;" @click="rewrite('cx')"/>
                </el-tooltip>
              </div>
              <emo-picker
                  v-show="showEmoPicker"
                  :inputId="'postContent'+index"
                  :showFrequentlyUsedList="false"
                  @select="emojiClick"
                  @selectBack="updateContent"/>
              <div v-if="topics.length">
                <el-tag
                  v-for="topic in topics"
                  :key="topic"
                  @click="addTopic(topic)"
                  style="margin: 5px;"
                >{{ topic }}</el-tag>
              </div>
            </el-form-item>
            <div v-if="hasLanguageNames" style="margin-left: 120px;color:red;font-size: 12px;">
              {{$t('issueItem.tipOneTranslate')}}
            </div>
            <div v-for="(item, index) in form.socialIdsByTranslate" :key="index" style="margin-left: 120px;">
              <div v-if="item.languageName">
                {{item.languageName}}（{{ item.accounts }}）
                <el-input type="textarea" v-model="item.text" @input="updateTranslation(index, item.text)"></el-input>
              </div>
            </div>
          </el-col>
          <el-col :span="10">
                 <!-- 从成品库选择上传视频 -->
            <el-form-item :label="$t('issueItem.selectVideo')" prop="video">
              <div class="aspect-ratio-box-container">
                <!-- <div class="aspect-ratio-box flex-column" @click="selectVideo" v-show="form.video.length==0">
                  <i class="el-icon-upload"></i>
                  <div style="margin-top: 5px;">
                    {{$t('issueItem.import')}}
                  </div>
                </div> -->
                <uploadFile
                  v-show="form.video.length==0"
                  @selectMaterial="selectMaterial"
                  @showLocaMedia="showLocaMedia"
                />
                <div
                  v-for="(material, index) in form.video"
                  :key="index"
                  class="aspect-ratio-box flex-column"
                  @mouseover="material.hover = true"
                  @mouseleave="material.hover = false"
                >
                  <div class="material-container">
                    <video :src="material.url" class="material-thumbnail"></video>
                    <div v-if="material.hover" class="overlay">
                      <div  @click="playVideo(material.url)">{{$t('issueItem.paly')}}</div>
                      <div style="margin: 5px;">|</div>
                      <div  @click="removeMaterial(index)">{{$t('issueItem.remove')}}</div>
                    </div>
                  </div>
                  <!-- <div style="margin-top: 5px;">{{ material.name }}</div> -->
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      <!-- 设置发布时间 -->
      <el-form-item :label="$t('issueItem.publishType')" prop="timeType">
        <el-radio-group v-model="form.timeType" @input="clearPublishTime">
          <el-radio label="0">{{$t('issueItem.sameTime')}}</el-radio>
          <el-radio label="1">{{$t('issueItem.customTime')}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.timeType === '0'" prop="publishTimeType">
        <el-radio-group v-model="form.publishTimeType" @input="clearPublishTime">
          <el-radio label="0">{{$t('issueItem.issueNow')}}</el-radio>
          <el-radio label="1">{{$t('issueItem.issueCountry')}}</el-radio>
          <el-radio label="2">{{$t('issueItem.issueUnify')}}</el-radio>
        </el-radio-group>
        <div v-if="form.publishTimeType === '1'">
          <el-date-picker
            :picker-options="pickerOptions"
            v-model="form.publishTime"
            type="datetime"
            :placeholder="$t('issueItem.tipDate')"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="changePickerNormal"
          ></el-date-picker>
        </div>
        <div style="color:red;font-size: 12px;" v-if="form.publishTimeType === '1'">
          {{$t('issueItem.tipSet')}}
        </div>
        <div v-if="form.publishTimeType === '2'">
          <el-select v-model="form.timeZoneId" :placeholder="$t('issueItem.tipTimezone')" filterable @change="form.publishTime = ''">
            <el-option v-for="time in timeOptions" :key="time.id" :label="time.rawFormat"
              :value="time.id" class="ellipsis-option"></el-option>
          </el-select>
          <el-date-picker
            :picker-options="pickerOptions"
            v-model="form.publishTime"
            type="datetime"
            :placeholder="$t('issueItem.tipDate')"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="changePicker"
            :disabled="form.timeType === '0' && form.publishTimeType === '2' && !form.timeZoneId"
          ></el-date-picker>
        </div>
      </el-form-item>
      <el-form-item v-if="form.timeType === '1'" prop="socialTimeMap">
        <SocialTime
          :template="socialTimeMap"
          :timeOptions="timeOptions"
          @refreshMap="refreshMap"
        />
        <div style="color:red;font-size: 12px;" v-if="!socialTimeMap||(Object.keys(socialTimeMap).length === 0)">
          {{$t('issueItem.tipSocialTime')}}
        </div>
      </el-form-item>
    </el-form>

    <i class="iconfont icon-delete"
      style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%); cursor: pointer;"
      @click="$emit('remove')"></i>

    <el-drawer
      :title="$t('issueItem.product')"
      size="65%"
      :visible.sync="drawer"
      :with-header="false">
      <generated-video ref="generated" @selectionConfirmed="selectionConfirmed"></generated-video>
    </el-drawer>
    <material-drawer
      ref="materialDrawer"
      :visible.sync="drawerMaterial"
      @selectionConfirmed="selectionText"
    />
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('issueItem.translateTo')"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="50%"
    >
      <el-select v-model="language" :placeholder="$t('issueItem.translateLanguage')" filterable clearable style="width: 100%;">
        <el-option v-for="dict in languageList" :key="dict.code" :label="dict.name"
          :value="dict.code" />
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">{{$t('cancel')}}</el-button>
        <el-button type="primary" :loading="loading" @click="handleConfirm">{{$t('confirm')}}</el-button>
      </span>
    </el-dialog>
     <!-- 弹窗播放视频 -->
     <el-dialog :visible.sync="dialogPlay" width="50%" :before-close="handleClose">
      <video controls :src="playUrl" class="video-player" ref="videoPlayer"></video>
    </el-dialog>
  </el-card>
</template>

<script>
import { batchTranslate, rewrite, translate } from '../../api/ai/language';
import EmoPicker  from '../../components/EmoPicker/EmoPicker.vue';
import GeneratedVideo from '../../views/aivideo/generatedVideo.vue';
import MaterialDrawer from '@/components/MaterialDrawer/index.vue';
import SocialTime from './socialTime.vue';
import { formatDate } from '@/utils/index'
import uploadFile from '../../components/uploadFile/index.vue'

export default {
  name: 'IssueItem',
  components: { EmoPicker ,GeneratedVideo, MaterialDrawer, SocialTime, uploadFile },
  props: {
    accountList: {
      type: Array,
      required: true
    },
    newAccounts: {
      type: Array,
      required: true
    },
    timeOptions: {
      type: Array,
      required: true
    },
    languageOptions: {
      type: Array,
      required: true
    },
    value: Object, // 父组件传入的form对象
    index:{
      type: Number,
      default: 0,
    },
    languageList: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    hasLanguageNames() {
      return this.form.socialIdsByTranslate.some(item => item.languageName);
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.form = newVal;
        if(this.form.socialTimeMap){
          this.socialTimeMap = this.form.socialTimeMap;
        }
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      pickerOptions: {
        // 禁用掉今天之前的日期不让选择
        disabledDate(time) {
          const today = new Date();
          today.setHours(0, 0, 0, 0); // 设置为今天的零点
          return time.getTime() < today.getTime();
        },
      },
      form: {
        socialIds: [],
        socialIdsByTranslate:[],
        postContent: '',
        video: [],
        publishTimeType: '0',
        publishTime: '',
        socialTimeMap: {},
        ...this.value
      },
      // form: this.value,
      rules: {
        socialIds: [{
          required: true,
          message: this.$t('issueItem.tipAccount'),
          trigger: ['blur'],
        }],
        postContent: [{ required: true, message: this.$t('issueItem.tipPost'), trigger: 'blur' }],
        video: [{ required: true, message: this.$t('issueItem.tipVideo'), trigger: ['blur', 'change'] }],
        timeType: [{ required: true, message: this.$t('issueItem.tipTimeType'), trigger: ['blur', 'change'] }],
        publishTimeType: [{ required: true, message: this.$t('issueItem.tipTimeType'), trigger: ['blur', 'change'] }],
      },
      topics: [],
      showEmoPicker:false,
      drawer:false,//成品库
      dialogVisible: false,//选择翻译语言
      language: 'en',//需要翻译成的语言
      loading:false,//选择翻译语言的确定按钮
      dialogPlay: false,
      videoElement: null,  // 添加一个变量用于保存视频元素
      playUrl: '',  // 播放视频的URL
      drawerMaterial:false,//文案素材库
      props:{
        // value:'name',
        // label:'name',
        emitPath: false,
        multiple: true
      },
      socialTimeMap: {},//自定义时间——从form挪到外面
      speechText: '',//视频文案
    };
  },
  created(){
  },
  methods: {
    //清空时间，必须先选择时区才能选择时间
    clearPublishTime(){
      if(this.form.timeType === '0' && this.form.publishTimeType === '2' && !this.form.timeZoneId){
        this.form.publishTime = '';
      }
    },
    //默认当前时区的时间加1小时
    changePickerNormal(){
      const startAt = new Date(this.form.publishTime).getTime();
      const now = new Date();
      now.setHours(now.getHours() + 1); // 当前时间加1小时
      if (startAt < now.getTime()) {
        this.form.publishTime = formatDate(now); // 设置为当前时间加1小时
      }
    },
    //选中时区的时间加1小时
    changePicker(selectedTime) {
      // 获取用户选择的时区偏移量
      let timeZone = this.timeOptions.find(option => option.id === this.form.timeZoneId) || {};
      const timeZoneOffset = timeZone.utc || '+08:00'; // 示例： '+08:00' or '-12:00'
      console.log('timeZoneOffset', timeZoneOffset);
      // 将时区偏移量转换为小时数
      const offsetHours = this.convertTimezoneOffsetToHours(timeZoneOffset);

      // 以时区偏移计算选择的时间
      const startAt = this.applyTimezoneOffset(new Date(selectedTime), offsetHours).getTime();

      // 获取当前UTC时间，并加1小时
      const now = new Date(); // 当前时间
      const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000); // UTC时间
      utcNow.setHours(utcNow.getHours() + 1);
      const nowWithOffset = this.applyTimezoneOffset(utcNow, offsetHours);

      // 如果选择的时间小于当前时区时间+1小时，则重置为当前时区时间+1小时
      if (startAt < nowWithOffset.getTime()) {
        this.form.publishTime = this.formatDate(nowWithOffset);
      }
      console.log('this.form.publishTime', this.form.publishTime);
    },

    // 将时区偏移量（如 +08:00）转换为小时
    convertTimezoneOffsetToHours(offset) {
      const sign = offset.startsWith('-') ? -1 : 1;
      const [hours, minutes] = offset.slice(1).split(':').map(Number);
      return sign * (hours + minutes / 60);
    },

    // 将日期对象应用时区偏移量
    applyTimezoneOffset(date, offsetHours) {
      const dateWithOffset = new Date(date);
      dateWithOffset.setHours(dateWithOffset.getHours() + offsetHours);
      return dateWithOffset;
    },

    formatDate(date) {
      // 格式化日期为 yyyy-MM-dd HH:mm:ss 格式
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    refreshMap(map){
      this.socialTimeMap = map;
    },
    updateSocialTimeMap() {
      const currentIds = new Set(this.socialTimeMap ? Object.keys(this.socialTimeMap) : []);

      const filteredAccounts = this.accountList.filter(account => this.form.socialIds.includes(account.id));
      const originalSocialTimeMap = { ...this.socialTimeMap };
      // 清空 socialTimeMap
      this.socialTimeMap = {};
      filteredAccounts.forEach(account => {
        if (currentIds.has(account.id)) {
          // 如果该 ID 已存在，保留原值
          this.socialTimeMap[account.id] = {
            ...originalSocialTimeMap[account.id]
          };
        } else {
          // 如果是新增 ID，设置默认值
          this.$set(this.socialTimeMap, account.id, {
            displayName: account.displayName,
            platform: account.platform,
            publishTimeType: '0', // 默认值
            publishTime: null,     // 默认值
            timeZoneId: null       // 默认值
          });
        }
      });
      console.log('this.socialTimeMap',this.socialTimeMap)
    },
    changePost(){
      if(this.form.socialIdsByTranslate.length>0){
        this.form.socialIdsByTranslate = this.form.socialIdsByTranslate.map(item => {
          if (!item.languageName) {
            item.text = this.form.postContent;
          }
          return item;
        });
      }
    },
    updateTranslation(index, newDst) {
      this.form.socialIdsByTranslate[index].text = newDst;
      console.log('this.form.socialIdsByTranslate',this.form.socialIdsByTranslate)
    },
    //导入文案素材
    importText(){
      this.drawerMaterial = true
      this.$nextTick().then((rs) => {
        this.$refs.materialDrawer.$refs.materialLibrary.init(4)
      });
    },
    selectionText(items){
      if(items.length==1){
        this.form.postContent = items[0].content
      }
      this.drawerMaterial = false
    },
    playVideo(url) {
      this.playUrl = url
      this.dialogPlay = true;
       // 播放视频时获取视频元素
       this.$nextTick(() => {
        this.videoElement = this.$refs.videoPlayer;
        this.videoElement.play();
      });
    },
    handleClose() {
      this.dialogPlay = false;
       // 关闭弹窗时停止视频播放
       if (this.videoElement) {
        this.videoElement.pause();
        this.videoElement.currentTime = 0; // 可选：将视频播放位置重置为起始位置
      }
    },
    reset(){
      this.form.socialIdsByTranslate = []
      this.form.postContent = ''
    },
    //一键翻译
    oneTranslate(){
      if(!this.form.socialIds||this.form.socialIds.length == 0){
        this.$modal.msgError(this.$t('issueItem.tipAccount'));
        return;
      }
      if(!this.form.postContent){
        this.$modal.msgError(this.$t('issueItem.tipPostFirst'));
        return;
      }
      const accounts = this.accountList.filter(account => this.form.socialIds.includes(account.id)) || [];
      if (accounts.length > 0) {
        const allNoBdCode = accounts.every(acc => !acc.bdLanguageCode);
        if (allNoBdCode) {
          this.$modal.msgError(this.$t('issueItem.tipBdCode'));
          return;
        }
        const codes = accounts.filter(acc => acc.bdLanguageCode).map(acc => acc.bdLanguageCode);
        batchTranslate({
          text: this.form.postContent,
          to: codes.join(','),
          from:'auto'
        }).then(response => {
          if(response.data && response.data.length > 0){
            let translateList = response.data
            this.form.socialIdsByTranslate = translateList.map(item => {
              const languageId = this.accountList.find(account => account.bdLanguageCode === item.to).languageId
              const language = this.languageOptions.find(item => item.id === languageId);
              const parts = language.name.split('(');
              if (parts.length > 0) {
                const languageName = parts[0].trim();
                const accounts = this.accountList.filter(account => account.bdLanguageCode === item.to) || []
                const names = accounts.map(account => account.username);
                const ids = accounts.map(account => account.id);
                return {
                  text:item.dst,
                  socialIds:Array.isArray(ids) ? ids : [ids],
                  languageName:languageName,
                  accounts: names.join(','),
                };
              }
            });
            const noBdCode = this.accountList.filter(account => !account.bdLanguageCode) || []
            if(noBdCode.length>0){
              const names = noBdCode.map(account => account.username);
              const ids = noBdCode.map(account => account.id);
              this.form.socialIdsByTranslate.push({
                text:this.form.postContent,
                socialIds:Array.isArray(ids) ? ids : [ids],
                languageName:'',
                accounts: names.join(','),
              })
            }
            console.log('this.form.socialIdsByTranslate',this.form.socialIdsByTranslate)
          }
        }).finally(() => {
          this.loading = false;
        });
      }else{
        this.$modal.msgError(this.$t('issueItem.tipNotFind'));
      }
    },
    //选择翻译语言
    chooseLanguage(){
      if(!this.form.postContent){
        this.$modal.msgError(this.$t('issueItem.tipPostFirst'));
        return;
      }
      this.dialogVisible = true;
    },
    handleCancel(){
      this.dialogVisible = false;
    },
    handleConfirm(){
      if(!this.language){
        this.$modal.msgError(this.$t('issueItem.tipTranslate'));
        return;
      }
      this.loading = true
      translate({
        text: this.form.postContent,
        to: this.language,
        from:'auto'
      }).then(response => {
        this.dialogVisible = false;
        if(response.data && response.data.trans_result && response.data.trans_result.length > 0){
          let result = response.data.trans_result[0].dst;
          if(result)
            this.form.postContent = result;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
     //重写
     rewrite(type){
      if(!this.form.postContent){
        this.$modal.msgError(this.$t('issueItem.tipPostFirst'));
        return;
      }
      this.loading = true
      rewrite({
        content: this.form.postContent,
        type: type
      }).then(res => {
        this.form.postContent = res.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    getAccount(account){
      if (account) {
        return account.username + this.formatLanguage(account.languageId)
      }
      return ''
    },
    formatLanguage(languageId) {
      const language = this.languageOptions.find(item => item.id === languageId);
      if (language) {
        const parts = language.name.split('(');
        if (parts.length === 2) {
          const country = parts[1].replace(')', '').trim();
          const languageName = parts[0].trim();
          return `（${country} ${languageName}）`;
        }
        return language.name;
      }
      return '';
    },
    emojiClick(emoji){
      console.log(emoji)
      this.showEmoPicker = false
    },
    updateContent(resultText){
      this.form.postContent = resultText
    },
    addEmoji(e) {
      var elInput = document.getElementById('emojiInput'); //根据id选择器选中对象
      var startPos = elInput.selectionStart;// input 第0个字符到选中的字符
      var endPos = elInput.selectionEnd;// 选中的字符到最后的字符
      if (startPos === undefined || endPos === undefined) return
      var txt = elInput.value;
      // 将表情添加到选中的光标位置
      var result = txt.substring(0, startPos) + e.native + txt.substring(endPos)
      elInput.value = result;// 赋值给input的value
       // 重新定义光标位置
      elInput.focus();
      elInput.selectionStart = startPos + e.native.length;
      elInput.selectionEnd = startPos + e.native.length;
      this.content  = result// 赋值给表单中的的字段
    },
    searchTopic() {
      // 模拟搜索话题功能
      if (this.form.postContent.includes('#')) {
        this.topics = ['话题1', '话题2', '话题3'];
      } else {
        this.topics = [];
      }
    },
    addTopic(topic) {
      this.form.postContent += topic;
    },
    selectMaterial() {
      this.selectVideo()
    },
    selectVideo() {
      this.drawer = true
      this.$nextTick().then((rs) => {
        this.$refs.generated.init(1);
      });
    },
    showLocaMedia(items) {
      this.selectionConfirmed(items)
    },
    selectionConfirmed(items) {
      if(items.length==1){
        this.form.video = [{
          ...items[0],
          hover: false, // 添加一个hover属性来控制蒙层的显示
        }]
        this.form.url = items[0].url
        this.form.productId = items[0].id
        if(items[0].speechSummarize){
          this.form.postContent = items[0].speechSummarize
        }
        this.speechText = items[0].speechText || ''
      }
      console.log('选中的素材:', items)
      // 这里可以执行进一步的处理，例如保存到数据中等
      this.drawer = false
    },
    removeMaterial(index){
      this.form.video=[];
      this.form.url = ''
      this.form.productId = ''
      this.speechText = ''
    },
    validateSocialTimeMap() {
      const errors = {};
      for (const id in this.socialTimeMap) {
        const item = this.socialTimeMap[id];
        if (item.publishTimeType === '0') {
            // publishTimeType 为 '0'，publishTime 和 timeZoneId 可以为空
            continue;
        } else if (item.publishTimeType === '1') {
            // publishTimeType 为 '1'，publishTime 必填
            if (!item.publishTime) {
                errors[id] = { publishTime: 'Publish time is required.' };
            }
        } else if (item.publishTimeType === '2') {
            // publishTimeType 为 '2'，publishTime 和 timeZoneId 必填
            if (!item.publishTime) {
                errors[id] = { publishTime: 'Publish time is required.' };
            }
            if (!item.timeZoneId) {
                if (!errors[id]) {
                    errors[id] = {};
                }
                errors[id].timeZoneId = 'Time zone ID is required.';
            }
        }
      }

      return errors;
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            console.log('this.form.socialIds',this.form.socialIds)
            if(this.form.timeType == '0'){
              if((this.form.publishTimeType=='1' || this.form.publishTimeType=='2')&& !this.form.publishTime){
                this.$modal.msgError(this.$t('issueItem.tipIssueTime'))
                return
              }
              if(this.form.publishTimeType=='2' && !this.form.timeZoneId){
                this.$modal.msgError(this.$t('issueItem.tipIssueTimezone'))
                return
              }
              if(this.form.publishTimeType === '1'){
                const accounts = this.accountList.filter(account => this.form.socialIds.includes(account.id)) || [];
                if (accounts.length > 0) {
                  const hasNoLanguageId = accounts.some(acc => !acc.languageId);
                  if (hasNoLanguageId) {
                    this.$modal.msgError(this.$t('issueItem.tipSetNo'));
                    return
                  }
                }
              }
              this.form.socialTimeMap = null
            } else if(this.form.timeType == '1'){
              const validationErrors = this.validateSocialTimeMap();
              if (Object.keys(validationErrors).length > 0) {
                // 存在错误，处理逻辑
                this.$modal.msgError(this.$t('issueItem.tipIssueTimeAll'));
                return
              }
              this.form.socialTimeMap = this.socialTimeMap;
            }
            resolve(true);
          } else {
            reject(false);
          }
        });
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.aspect-ratio-box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.aspect-ratio-box {
  width: 90px;
  height: 160px;
  border: 1px solid #0092FF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px;
  position: relative;
}
.material-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}
.material-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}
::v-deep .el-input__count {
  bottom: -5px; /* 调整显示位置 */
  right: 10px; /* 根据需要调整 */
  transform: translateY(100%); /* 防止遮挡 */
  color: #909399; /* 可根据需要调整颜色 */
}
</style>
