var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-page" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "video-container flex-center",
      },
      [
        _c(
          "div",
          { ref: "carouselContainer", staticClass: "carousel-container" },
          [
            _c("div", { staticClass: "flex-row" }, [
              _c(
                "div",
                { staticClass: "video-frame-container flex-column f-between" },
                [
                  _c("video", {
                    ref: "videoRefs",
                    staticClass: "video-player-info",
                    attrs: { src: _vm.detail.url, controls: "", autoplay: "" },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "bottom-content flex-row-center f-between f-shrink",
                    },
                    [
                      _c("el-avatar", {
                        staticClass: "f-shrink",
                        attrs: { size: 48, src: _vm.detail.socialAvatar },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "name ml10 f-grow single-line-ellipsis",
                        },
                        [_vm._v(_vm._s(_vm.detail.socialAccount))]
                      ),
                      _c("span", { staticClass: "time ml10 f-shrink" }, [
                        _vm._v(_vm._s(_vm.timeAgo(_vm.detail.publishTime))),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "video-nums flex-column-center f-between" },
                [
                  _c("div", { staticClass: "icon-container flex-center" }, [
                    _c("i", {
                      staticClass: "iconfont socialType-icon",
                      class: [
                        "icon-" + _vm.detail.platform,
                        _vm.detail.platform,
                      ],
                    }),
                  ]),
                ]
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }