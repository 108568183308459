<template>
  <div style="height: 100%; width: 100%;">
    <!-- Facebook SDK 根节点 -->
    <div id="fb-root"></div>

    <!-- 嵌入 Facebook 视频 -->
    <div
      class="fb-video"
      :data-href="videoUrl"
      data-width="500"
      data-show-text="false"
    >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    videoUrl: {
      type: String,
      default: ''
    }
  },
  mounted() {
    // 检查 Facebook SDK 是否已加载
    if (!document.getElementById("facebook-jssdk")) {
      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.async = true;
      script.defer = true;
      script.crossorigin = "anonymous";
      script.src =
        "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v16.0";
      document.body.appendChild(script);
    } else {
      // 如果已加载，直接初始化 Facebook 插件
      this.initFacebook();
    }
  },
  methods: {
    // 初始化 Facebook 插件
    initFacebook() {
      if (window.FB) {
        this.$nextTick(() => {
          window.FB.XFBML.parse(); // 解析页面中的 Facebook 插件
        });
      }
    },
  },
  watch: {
    // 如果 videoUrl 动态更改，重新初始化插件
    videoUrl() {
      this.initFacebook();
    },
  },
};
</script>
<style scoped>
.fb-video {
  height: 100%;
  width: 100%;
  /* margin: 20px auto; */
}
</style>
