var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cardForm", on: { click: _vm.clearTopics } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form-top",
          attrs: { "label-position": "top", model: _vm.form, rules: _vm.rules },
        },
        [
          _c("div", { staticClass: "flex-row" }, [
            _c(
              "div",
              { staticClass: "leftUpload f-shrink" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("issueItem.selectVideo"),
                      prop: "video",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "aspect-ratio-box-container" },
                      [
                        _c("upload-file", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.video.length == 0,
                              expression: "form.video.length==0",
                            },
                          ],
                          attrs: { title: _vm.$t("file.selectProduct") },
                          on: {
                            selectMaterial: _vm.selectVideo,
                            showLocaMedia: _vm.showLocaMedia,
                          },
                        }),
                        _vm._l(_vm.form.video, function (material, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "aspect-ratio-box flex-column",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "material-container" },
                                [
                                  material.fileType === "image"
                                    ? _c("img", {
                                        staticClass: "material-thumbnail",
                                        attrs: {
                                          src: material.url,
                                          alt: "image",
                                        },
                                      })
                                    : _c("video", {
                                        staticClass: "material-thumbnail",
                                        attrs: { src: material.url },
                                      }),
                                  _c(
                                    "video-button",
                                    {
                                      attrs: {
                                        video: material,
                                        type: 2,
                                        tip: _vm.$t("view"),
                                      },
                                    },
                                    [
                                      _c("template", { slot: "customButton" }, [
                                        _c("div", { staticClass: "cricle" }, [
                                          _c("i", {
                                            staticClass: "el-icon-caret-right",
                                          }),
                                        ]),
                                      ]),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "deleteIcon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeMaterial(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-delete",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "rightForm" },
              [
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { position: "relative" },
                    attrs: {
                      label: _vm.$t("issueItem.post"),
                      prop: "postContent",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "textInput" },
                      [
                        _c("el-input", {
                          attrs: {
                            id: "postContent" + _vm.index,
                            maxlength: "2000",
                            "show-word-limit": "",
                            type: "textarea",
                            autosize: { minRows: 4, maxRows: 6 },
                            placeholder: _vm.$t("issueItem.tipPost"),
                          },
                          on: { input: _vm.changePost },
                          model: {
                            value: _vm.form.postContent,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "postContent", $$v)
                            },
                            expression: "form.postContent",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "importText",
                            on: { click: _vm.importText },
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont icon-a-dagoubeifen21",
                            }),
                            _vm._v(_vm._s(_vm.$t("basicInfo.materialImport"))),
                          ]
                        ),
                        _c("div", { staticClass: "flexInputBtn" }, [
                          _c(
                            "div",
                            {
                              staticClass: "btnBox",
                              on: { click: _vm.addSymbol },
                            },
                            [_vm._v("#" + _vm._s(_vm.$t("issueItem.topic")))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btnBox",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  _vm.showEmoPicker = !_vm.showEmoPicker
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-a-danyuanpeizhi2",
                              }),
                              _vm._v(_vm._s(_vm.$t("issueItem.emo"))),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btnBox",
                              on: { click: _vm.chooseLanguage },
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-danyuanpeizhi",
                              }),
                              _vm._v(_vm._s(_vm.$t("issueItem.translate"))),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btnBox",
                              on: {
                                click: function ($event) {
                                  return _vm.rewrite("sc")
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "iconfont icon-zongjie" }),
                              _vm._v(_vm._s(_vm.$t("subtitle.sumup"))),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btnBox",
                              on: {
                                click: function ($event) {
                                  return _vm.rewrite("sx")
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-a-danyuanpeizhi4",
                              }),
                              _vm._v(_vm._s(_vm.$t("subtitle.abbreviation"))),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btnBox",
                              on: {
                                click: function ($event) {
                                  return _vm.rewrite("kx")
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-a-danyuanpeizhi5",
                              }),
                              _vm._v(_vm._s(_vm.$t("subtitle.expand"))),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btnBox",
                              on: {
                                click: function ($event) {
                                  return _vm.rewrite("cx")
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-a-danyuanpeizhi6",
                              }),
                              _vm._v(_vm._s(_vm.$t("subtitle.rewrite"))),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("emo-picker", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showEmoPicker,
                          expression: "showEmoPicker",
                        },
                      ],
                      attrs: {
                        inputId: "postContent" + _vm.index,
                        showFrequentlyUsedList: false,
                      },
                      on: {
                        select: _vm.emojiClick,
                        selectBack: _vm.updateContent,
                      },
                    }),
                    _vm.topics.length > 0
                      ? _c(
                          "div",
                          { staticClass: "topics-box" },
                          _vm._l(_vm.topics, function (item, idx) {
                            return _c(
                              "div",
                              {
                                key: idx,
                                staticClass:
                                  "topics-item flex-row-center f-between pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.selectTopic(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "topics-title f-grow single-line-ellipsis",
                                  },
                                  [_vm._v("#" + _vm._s(item.name))]
                                ),
                                _c("span", { staticClass: "f-shrink" }, [
                                  _vm._v(
                                    _vm._s(_vm.formatCount(item.viewCount)) +
                                      " 观看次数"
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("issueItem.account"),
                      prop: "socialIds",
                    },
                  },
                  [
                    _c("div", { staticClass: "accountBox" }, [
                      _c(
                        "div",
                        { staticClass: "accountHeader" },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$t("issueItem.publishTime")) + ":"
                            ),
                          ]),
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                "flex-grow": "1",
                                "margin-right": "10px",
                              },
                              model: {
                                value: _vm.selectTimeType,
                                callback: function ($$v) {
                                  _vm.selectTimeType = $$v
                                },
                                expression: "selectTimeType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("issueItem.issueNow"),
                                  value: 0,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("issueItem.issueCountry"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("issueItem.issueUnify"),
                                  value: 2,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("issueItem.customTime"),
                                  value: 3,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "accountContent" }, [
                        _c("div", [
                          _vm.selectTimeType === 1
                            ? _c(
                                "div",
                                { staticClass: "mb16" },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      "picker-options": _vm.pickerOptions,
                                      type: "datetime",
                                      placeholder: _vm.$t("issueItem.tipDate"),
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                    },
                                    on: { change: _vm.changePickerNormal },
                                    model: {
                                      value: _vm.form.publishTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "publishTime", $$v)
                                      },
                                      expression: "form.publishTime",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectTimeType === 2
                            ? _c(
                                "div",
                                { staticClass: "mb16" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "issueItem.tipTimezone"
                                        ),
                                        filterable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.form.publishTime = ""
                                        },
                                      },
                                      model: {
                                        value: _vm.form.timeZoneId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "timeZoneId", $$v)
                                        },
                                        expression: "form.timeZoneId",
                                      },
                                    },
                                    _vm._l(_vm.timeOptions, function (time) {
                                      return _c("el-option", {
                                        key: time.id,
                                        staticClass: "ellipsis-option",
                                        attrs: {
                                          label: time.rawFormat,
                                          value: time.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" ———— "),
                                  _c("el-date-picker", {
                                    attrs: {
                                      "picker-options": _vm.pickerOptions,
                                      type: "datetime",
                                      placeholder: _vm.$t("issueItem.tipDate"),
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      disabled: !_vm.form.timeZoneId,
                                    },
                                    on: { change: _vm.changePicker },
                                    model: {
                                      value: _vm.form.publishTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "publishTime", $$v)
                                      },
                                      expression: "form.publishTime",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectTimeType === 3
                            ? _c(
                                "div",
                                { staticClass: "mb16" },
                                [
                                  _c("SocialTime", {
                                    attrs: {
                                      template: _vm.socialTimeMap,
                                      timeOptions: _vm.timeOptions,
                                    },
                                    on: {
                                      refreshMap: _vm.refreshMap,
                                      deleteMap: _vm.deleteMap,
                                    },
                                  }),
                                  !_vm.selectAccountList.length
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "font-size": "12px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "issueItem.tipSocialTime"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _vm.selectAccountList.length && _vm.selectTimeType !== 3
                          ? _c(
                              "div",
                              _vm._l(_vm.selectAccountList, function (item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass: "accountItem",
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.mouseenterDel(item)
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.mouseleaveDel(item)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "accountItemBox" },
                                      [
                                        _c("el-avatar", {
                                          attrs: {
                                            size: 32,
                                            src: item.userImage,
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "mediaName" },
                                          [_vm._v(_vm._s(item.displayName))]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.mouseenterId === item.id
                                      ? _c("i", {
                                          staticClass: "iconfont icon-delete",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteAccount(item)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "addAccount" }, [
                          _c(
                            "div",
                            {
                              staticClass: "addIcon",
                              on: { click: _vm.selectAccount },
                            },
                            [_c("i", { staticClass: "iconfont icon-tianjia" })]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.$t("account.addAccount")) + " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-bottom": "0px" },
                    attrs: {
                      label: _vm.$t("issueVideo.taskName"),
                      prop: "taskName",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "taskName",
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: _vm.$t("tip.input"),
                        type: "textarea",
                        maxlength: "50",
                        "show-word-limit": "",
                      },
                      model: {
                        value: _vm.form.taskName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "taskName", $$v)
                        },
                        expression: "form.taskName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flexCenter" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "submitBtn btnWidth",
                        attrs: { type: "primary", loading: _vm.buttonLoading2 },
                        on: {
                          click: function ($event) {
                            return _vm.saveDraft(2)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-xingzhuangjiehe",
                        }),
                        _vm._v(_vm._s(_vm.$t("issueItem.issueNow"))),
                      ]
                    ),
                    !_vm.isCarry
                      ? _c(
                          "el-button",
                          {
                            staticClass: "cancelBtn btnWidth",
                            attrs: { loading: _vm.buttonLoading1 },
                            on: {
                              click: function ($event) {
                                return _vm.saveDraft(1)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont icon-sousuotiaojianbaocun",
                            }),
                            _vm._v(_vm._s(_vm.$t("issueVideo.saveDraft"))),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("issueItem.product"),
            size: "65%",
            visible: _vm.drawer,
            "with-header": false,
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("generated-video", {
            ref: "generated",
            on: {
              selectionConfirmed: _vm.selectionConfirmed,
              cancel: function ($event) {
                _vm.drawer = false
              },
            },
          }),
        ],
        1
      ),
      _c("material-drawer", {
        ref: "materialDrawer",
        attrs: { visible: _vm.drawerMaterial },
        on: {
          "update:visible": function ($event) {
            _vm.drawerMaterial = $event
          },
          selectionConfirmed: _vm.selectionText,
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "publicDialog",
          attrs: {
            width: "50%",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "headerFlex" }, [
              _c("div", { staticClass: "headerTitle" }, [
                _vm._v(_vm._s(_vm.$t("issueItem.translateTo"))),
              ]),
              _c("i", {
                staticClass: "el-icon-close",
                on: { click: _vm.handleCancel },
              }),
            ]),
          ]),
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: {
                placeholder: _vm.$t("issueItem.translateLanguage"),
                filterable: "",
                clearable: "",
                "default-first-option": true,
              },
              model: {
                value: _vm.language,
                callback: function ($$v) {
                  _vm.language = $$v
                },
                expression: "language",
              },
            },
            _vm._l(_vm.languageList, function (dict) {
              return _c("el-option", {
                key: dict.bdCode,
                attrs: { label: dict.language, value: dict.bdCode },
              })
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "cancelBtn btnWidth120",
                  on: { click: _vm.handleCancel },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
              _c(
                "el-button",
                {
                  staticClass: "submitBtn btnWidth120",
                  attrs: { loading: _vm.loading },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogPlay,
            width: "50%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPlay = $event
            },
          },
        },
        [
          _c("video", {
            ref: "videoPlayer",
            staticClass: "video-player",
            attrs: { controls: "", src: _vm.playUrl },
          }),
        ]
      ),
      _vm.accountVisible
        ? _c("selectAccountDialog", {
            attrs: {
              accountVisible: _vm.accountVisible,
              selectAccountList: _vm.selectAccountList,
            },
            on: { close: _vm.closeAccount, submitAccount: _vm.submitAccount },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }