<template>
  <div class="video-page">
    <!-- 主容器 -->
    <div
      class="video-container flex-center"
      v-loading="loading"
    >
      <div
        v-if="videoList.length > 0"
        class="flex-center"
      >
        <!-- 视频播放区域 -->
        <div>
          <div class="video-frame-container flex-column">
            <!-- <div
            class="facebook-video"
            v-if="currentVideo.socialType==='facebook'"
          >
            <facebook-video :videoUrl="currentVideo.externalPostUrl+'&autoplay=true&mute=false'"></facebook-video>
          </div> -->
            <!-- v-else -->
            <iframe
              v-if="isOverWall"
              :src="getVideoUrl(currentVideo)"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
            ></iframe>
            <video
              v-else
              :src="currentVideo.url"
              controls
              autoplay
              class="video-player"
            ></video>
            <div class="bottom-content flex-row-center f-between">
              <el-avatar
                :size="48"
                :src="currentVideo.avatarUrl"
                class="f-shrink"
              ></el-avatar>
              <span class="name ml10 f-grow single-line-ellipsis">{{ currentVideo.displayName }}</span>
              <span class="time ml10 f-shrink">{{ currentVideo.publishTime|dateDiff }}</span>
            </div>
          </div>
          <!-- 下一个视频预览 -->
          <div
            class="next-video-preview"
            v-if="nextVideo"
          >
            <!-- <div
            class="facebook-video"
            v-if="nextVideo.socialType==='facebook'"
          >
            <facebook-video :videoUrl="nextVideo.externalPostUrl"></facebook-video>
          </div> -->
            <!-- v-else -->
            <iframe
              v-if="isOverWall"
              :src="getNextVideoUrl(nextVideo)"
              frameborder="0"
              allowfullscreen
            ></iframe>
            <video
              v-else
              :src="nextVideo.url"
              class="video-player"
            ></video>
          </div>
        </div>
        <!-- 平台logo、播赞评转数量 -->
        <div class="video-nums flex-column-center f-between">
          <div class="icon-container flex-center">
            <i
              class="iconfont socialType-icon"
              :class="['icon-' + currentVideo.socialType, currentVideo.socialType]"
            ></i>
          </div>
          <div>
            <div class="flex-column-center mt10">
              <div class="video-nums-circle flex-center"><i class="iconfont icon-font icon-bofang1"></i> </div>
              <span class="video-nums-text">{{ currentVideo.views || 0 }}</span>
            </div>
            <div class="flex-column-center mt10">
              <div class="video-nums-circle flex-center"><i class="iconfont icon-font icon-dianzan"></i> </div>
              <span class="video-nums-text">{{ currentVideo.likes || 0 }}</span>
            </div>
            <div
              class="flex-column-center mt10 pointer"
              @click="showComment"
            >
              <div class="video-nums-circle flex-center"><i class="iconfont icon-font icon-pinglun"></i> </div>
              <span class="video-nums-text">{{ currentVideo.comments || 0 }}</span>
            </div>
            <div class="flex-column-center mt10">
              <div class="video-nums-circle flex-center"><i class="iconfont icon-font icon-zhuanfa"></i> </div>
              <span class="video-nums-text">{{ currentVideo.shares || 0 }}</span>
            </div>
          </div>
        </div>
        <!-- 评论 -->
        <div
          v-if="isShowComment"
          ref="scrollableDiv"
          class="video-frame-container video-comment flex-column"
        >
          <div class="comment-head flex-row-center">
            评论内容
            <div class="comment-head-num">{{ currentVideo.comments || 0 }}</div>
            <i
              class="el-icon-close comment-head-close"
              @click="isShowComment = false"
            ></i>
          </div>
          <div class="comment-bottom flex-column">

            <div
              class="f-grow"
              v-loading="commentLoading"
              style="overflow-y: auto;"
            >
              <div v-if="commentList.length>0">
                <CommentList
                  :comments="commentList"
                  :postId="currentVideo.id"
                  :socialAccounts="getCurrentAccounts"
                />
              </div>
              <el-empty
                v-if="!commentLoading && commentList.length === 0"
                description="暂无评论"
              ></el-empty>
            </div>
            <Comment
              v-if="socialAccounts.length > 0 && getCurrentAccounts.length > 0"
              :socialAccounts="getCurrentAccounts"
              :postId="currentVideo.id"
              @commentSuccess="commentSuccess"
            />
          </div>
        </div>
        <!-- 右侧按钮 -->
        <div class="video-controls">
          <div
            class="arrowButton flex-center"
            @click="playPreviousVideo"
            v-if="currentVideoIndex!==0"
          >
            <i class="el-icon-arrow-up icon"></i>
          </div>
          <div
            style="width: 58px; height: 58px;"
            v-if="currentVideoIndex==0"
          ></div>
          <div
            class="arrowButton flex-center"
            @click="playNextVideo"
            v-if="videoList.length > 1"
          >
            <!-- 正常数据 && currentVideoIndex !== videoList.length-1 -->
            <i class="el-icon-arrow-down icon"></i>
          </div>
        </div>
      </div>
      <el-empty
        description="暂无发布流数据"
        :image-size="200"
        :image="require('@/assets/logo/logo.png')"
        v-if="!loading && videoList.length === 0"
      >
        <el-button
          class="gradient-button"
          @click="goPublish"
        >去发布</el-button>
      </el-empty>
    </div>
  </div>
</template>
<script>
import { listPublish, queryComments } from "../../api/ai/publish";
import FacebookVideo from "./components/FacebookVideo.vue";
import CommentList from "../../components/Comment/CommentList.vue";
import Comment from "../../components/Comment/Comment.vue";
import { listAllAccount } from "../../api/account/tiktok";

export default {
  components: {
    FacebookVideo,
    CommentList,
    Comment,
  },
  data() {
    return {
      currentVideoIndex: 0,
      loading: false,
      videoList: [], //视频列表
      isOverWall: false, //是否翻墙
      scrolling: false, // 防止快速多次触发的状态变量
      socialAccounts: [], // 社交账号列表
      isShowComment: false, // 是否显示评论
      commentLoading: false, // 评论加载状态
      commentList: [], //评论列表
    };
  },
  computed: {
    currentVideo() {
      // 正常数据
      // return this.videoList[this.currentVideoIndex];
      // 确保索引有效（循环逻辑）
      const currentIndex = this.currentVideoIndex % this.videoList.length;
      return this.videoList[currentIndex] || {};
    },
    nextVideo() {
      // 正常数据
      // if (this.currentVideoIndex < this.videoList.length - 1) {
      //   return this.videoList[this.currentVideoIndex + 1];
      // } else {
      //   return "";
      // }
      // 数据少的情况下循环
      const nextIndex = (this.currentVideoIndex + 1) % this.videoList.length;
      return this.videoList[nextIndex] || {};
    },
    getCurrentAccounts() {
      // 如果 socialAccounts 尚未加载（为空），返回空数组
      if (!this.socialAccounts || this.socialAccounts.length === 0) {
        return [];
      }
      const a = this.socialAccounts.filter(
        (item) => item.platform === this.currentVideo.socialType
      );
      // 过滤符合条件的平台
      console.log("socialAccounts不为空", a);
      return this.socialAccounts.filter(
        (item) => item.platform === this.currentVideo.socialType
      );
    },
  },
  mounted() {
    this.getSocialAccountList();
    this.checkIfBlocked();
    this.getList();
    // 添加鼠标滚动监听
    window.addEventListener("wheel", this.handleMouseScroll);
  },
  beforeDestroy() {
    // 移除鼠标滚动监听
    window.removeEventListener("wheel", this.handleMouseScroll);
  },
  methods: {
    commentSuccess(data) {
      this.commentList.unshift(data);
    },
    // 点击评论按钮
    showComment() {
      this.isShowComment = true;
      this.queryComments();
    },
    // 获取评论列表
    queryComments() {
      this.commentLoading = true;
      queryComments({
        publishId: this.currentVideo.id,
      })
        .then((res) => {
          this.commentList = res.rows;
        })
        .finally(() => {
          this.commentLoading = false;
        });
    },
    // 获取账号列表
    getSocialAccountList() {
      listAllAccount().then((res) => {
        const newList = res.data.map((item) => {
          return { ...item, isSelected: false };
        });
        this.socialAccounts = newList;
      });
    },

    async checkIfBlocked() {
      try {
        const response = await fetch("https://www.google.com", {
          method: "HEAD",
          mode: "no-cors",
        });
        this.isOverWall = true; // 能访问，已翻墙
      } catch (error) {
        this.isOverWall = false; // 请求失败，未翻墙
      }
    },
    goPublish() {
      this.$router.push({ path: "/issueManager" });
    },
    getVideoUrl(item) {
      if (!item.externalPostUrl) return "";
      const videoId = item.externalPostUrl.split("/").pop();
      if (item.socialType === "tiktok") {
        return `https://www.tiktok.com/player/v1/${videoId}?autoplay=1&mute=0&loop=1&music_info=0&description=0`;
      } else if (item.socialType === "youtube") {
        return `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=0&controls=1&rel=0&showinfo=0&loop=1&playlist=${videoId}`;
      } else if (item.socialType === "twitter") {
        return `https://twitframe.com/show?url=${item.externalPostUrl}&hide_thread=true`;
      } else if (item.socialType === "instagram") {
        return `https://www.instagram.com/p/${videoId}/embed/?hidecaption=true`;
      } else if (item.socialType === "facebook") {
        return `https://www.facebook.com/plugins/video.php?href=${item.externalPostUrl}&mute=1&autoplay=1&show_text=false`;
      } else return item.externalPostUrl;
    },
    getNextVideoUrl(item) {
      if (!item.externalPostUrl) return "";
      const videoId = item.externalPostUrl.split("/").pop();
      if (item.socialType === "tiktok") {
        return `https://www.tiktok.com/player/v1/${videoId}?autoplay=0&loop=1&music_info=0&description=0`;
      } else if (item.socialType === "youtube") {
        return `https://www.youtube.com/embed/${videoId}?autoplay=0&controls=1&rel=0&showinfo=0&loop=1&playlist=${videoId}`;
      } else if (item.socialType === "twitter") {
        return `https://twitframe.com/show?url=${item.externalPostUrl}&hide_thread=true`;
      } else if (item.socialType === "instagram") {
        return `https://www.instagram.com/p/${videoId}/embed/?hidecaption=true`;
      } else if (item.socialType === "facebook") {
        return `https://www.facebook.com/plugins/video.php?href=${item.externalPostUrl}`;
      } else return item.externalPostUrl;
    },
    getList() {
      this.loading = true;
      // 发布列表，取发布状态是成功的，且externalPostUrl有值的
      listPublish({ showPub: true })
        .then((response) => {
          this.loading = false;
          this.videoList = response.rows.filter(
            (item) => item.status === 2 && item.externalPostUrl
          ); // 筛选数据
        })
        .catch(() => {
          this.loading = false;
        });
    },
    scrollToTop() {
      this.$refs.videoFrame.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    playNextVideo() {
      this.isShowComment = false;
      // 正常数据
      // if (this.currentVideoIndex < this.videoList.length - 1) {
      //   this.currentVideoIndex++;
      // }
      // 循环切换到下一个视频
      this.currentVideoIndex =
        (this.currentVideoIndex + 1) % this.videoList.length;
    },
    playPreviousVideo() {
      this.isShowComment = false;
      // 正常数据
      // if (this.currentVideoIndex > 0) {
      //   this.currentVideoIndex--;
      // }
      // 循环切换到上一个视频
      this.currentVideoIndex =
        (this.currentVideoIndex - 1 + this.videoList.length) %
        this.videoList.length;
    },
    handleMouseScroll(event) {
      // 获取目标 div 元素
      const scrollableDiv = this.$refs.scrollableDiv;

      // 判断滚动事件是否发生在目标 div 内
      if (scrollableDiv && scrollableDiv.contains(event.target)) {
        // 如果是目标 div 内部，直接返回，避免执行滚动操作
        return;
      }
      if (this.scrolling) return; // 防抖处理，正在滚动时直接返回
      this.scrolling = true; // 设置正在滚动的标志
      if (event.deltaY > 0) {
        this.playNextVideo();
      } else {
        this.playPreviousVideo();
      }
      // 延迟解除防抖状态
      setTimeout(() => {
        this.scrolling = false;
      }, 300); // 设置滚动间隔为 300ms，可根据需要调整
    },
  },
};
</script>
<style scoped lang="scss">
.video-page {
  padding: 15px;
  margin: 15px;
  background: #fff;
  width: 100%;
  height: calc(100vh - 120px);
  overflow: hidden;
  border-radius: 10px;
}

.video-container {
  width: 100%;
  height: calc(100vh - 150px);
  background: #fafcfd;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
}

.video-frame-container {
  height: calc(100vh - 220px); /* 固定高度为90% */
  width: calc((100vh - 220px) * 9 / 16);
  overflow: hidden;
  margin: 8px 0 16px;
}
.facebook-video {
  width: 100%;
  height: calc(100vh - 300px);
  overflow: hidden;
  border-radius: 20px;
}
.video-player {
  width: 100%;
  height: calc(100vh - 300px);
  border: none;
  border-radius: 20px;
  overflow: hidden; /* 隐藏滚动条 */
  display: block;
  scrollbar-width: none;
  background: #000;
}

.video-frame-container iframe {
  /* position: absolute; */
  /* top: 0;
  left: 0; */
  width: 100%;
  height: calc(100vh - 300px);
  border: none;
  border-radius: 20px;
  overflow: hidden; /* 隐藏滚动条 */
  display: block;
  scrollbar-width: none;
}
.bottom-content {
  width: 100%;
  height: 80px;
  padding: 0 16px;

  .name {
    height: 20px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 20px;
    text-align: left;
    font-style: normal;
  }
  .time {
    height: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 18px;
    text-align: left;
    font-style: normal;
  }
}

.next-video-preview {
  height: 40px; /* 固定高度为90% */
  width: calc((100vh - 220px) * 9 / 16);
  overflow: hidden;
  border-radius: 20px 20px 0 0;
}
.next-video-preview iframe {
  width: 100%;
  height: calc(100vh - 300px);
  border: none;
  border-radius: 20px;
}
.video-controls {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.arrowButton {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: rgba(1, 86, 255, 0.1);
  cursor: pointer;

  .icon {
    font-size: 24px;
    color: #0156ff;
  }
}
.arrowButton:hover {
  background: rgba(1, 86, 255, 0.2);
}

.video-nums {
  width: 48px;
  margin-left: 24px;
  height: calc(100vh - 340px);
  align-self: flex-start;
  padding-top: 8px;

  .icon-container {
    align-self: flex-start;
    width: 48px;
    height: 48px;
    border-radius: 24px; /* 圆角 */
    overflow: hidden; /* 确保内容裁剪 */
    box-sizing: border-box; /* 防止 padding 影响尺寸 */

    .socialType-icon {
      font-size: 48px;
    }
  }
  .video-nums-circle {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background: #f4f4f4;

    .icon-font {
      font-size: 28px;
      color: #0f0f0f;
    }
  }
  .video-nums-text {
    height: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 20px;
    font-style: normal;
  }
}
.video-comment {
  margin-left: 100px;
  background: white;
  border-radius: 20px;
  align-self: flex-start;
  border: 1px solid #dcdcdc;

  .comment-head {
    height: 48px;
    border-bottom: 1px solid #dcdcdc;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 22px;
    font-style: normal;
    padding: 0 24px;

    .comment-head-num {
      margin-left: 24px;
      flex-grow: 1;
    }
    .comment-head-close {
      font-size: 16px;
    }
  }
  .comment-bottom {
    height: calc(100% - 48px);
    padding: 16px 24px;
    flex-grow: 1;
  }
}

@media screen and (max-height: 900px) {
  .video-nums {
    width: 40px;
    margin-left: 24px;
    height: calc(100vh - 320px);
    align-self: flex-start;
    padding-top: 8px;

    .icon-container {
      align-self: flex-start;
      width: 40px;
      height: 40px;
      border-radius: 50%; /* 圆角 */
      overflow: hidden; /* 确保内容裁剪 */
      box-sizing: border-box; /* 防止 padding 影响尺寸 */

      .socialType-icon {
        font-size: 40px;
      }
    }
    .video-nums-circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #f4f4f4;

      .icon-font {
        font-size: 23px;
        color: #0f0f0f;
      }
    }
    .video-nums-text {
      height: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 18px;
      font-style: normal;
    }
  }
}
@media screen and (max-height: 768px) {
  .video-nums {
    width: 32px;
    margin-left: 24px;
    height: calc(100vh - 320px);
    align-self: flex-start;
    padding-top: 8px;

    .icon-container {
      align-self: flex-start;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden; /* 确保内容裁剪 */
      box-sizing: border-box; /* 防止 padding 影响尺寸 */

      .socialType-icon {
        font-size: 32px;
      }
    }
    .video-nums-circle {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #f4f4f4;

      .icon-font {
        font-size: 18px;
        color: #0f0f0f;
      }
    }
    .video-nums-text {
      height: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 16px;
      font-style: normal;
    }
  }
}
</style>