<template>
  <div class="app-container">
    <div class="header-container flex-row-center f-between mb20">
      <div class="flex-row-center">
        {{ $t('issueManager.issue') }}
      </div>
      <div
        class="flex-row-center pointer back-list"
        @click="goBack"
      >
        <i class="iconfont icon-fanhui mr8 " />
        {{ isCarry ? $t('issueVideo.backCarry') : $t('backList') }}
      </div>
    </div>
    <div v-loading="loading">
      <issue-item
        ref="issueItems"
        v-for="(form, index) in forms"
        v-model="forms[index]"
        :key="index"
        :index="index"
        :accountList="accountList"
        :newAccounts="newAccounts"
        :timeOptions="timeOptions"
        :languageOptions="languageOptions"
        :languageList="languageList"
        :addType="addType"
        :baseForm="baseForm"
        :isCarry="isCarry"
        :carryData="carryData"
      />
    </div>
  </div>
</template>

<script>
import IssueItem from "./issueItem.vue";
import { listAccount } from "../../api/account/tiktok";
import { getLanguages } from "../../api/ai/ali";
import { listLanguage, listTimeZone } from "../../api/ai/language";
import { getPublish } from "../../api/ai/publish";
import { getProductDetail } from "../../api/ai/product";

export default {
  name: "IssueVideo",
  components: {
    IssueItem,
  },
  data() {
    return {
      loading: false,
      baseForm: {
        id: "",
      },

      forms: [
        {
          socialIds: [],
          socialIdsByTranslate: [],
          postContent: "",
          video: [],
          timeType: "0",
          publishTimeType: "0",
          publishTime: "",
          timeZoneId: "",
          productId: "",
          url: "",
        },
      ],
      accountList: [],
      newAccounts: [],
      timeOptions: [],
      languageOptions: [],
      // 按钮loading
      buttonLoading1: false,
      buttonLoading2: false,
      languageList: [],
      addType: "add",
      isCarry: false, //来源是否为视频搬运，视频搬运时，发布接口独立
      carryData: {}, //视频搬运数据
    };
  },
  created() {
    this.getListAccount();
    this.getTimeZone();
    this.getLanguage();
    this.getLanguageList();
    let id = this.$route.params.id || "";
    let isId = this.$route.query.isId || false;
    let isProductId = this.$route.query.isProductId || false;
    this.isCarry = this.$route.query.isCarry || false;
    if (isId) this.baseForm.id = id;

    this.addType = this.$route.query.type || "add";
    if (this.baseForm.id) {
      this.getDetail();
    }
    let url = this.$route.query.url || "";
    if (url && isProductId) {
      this.forms = [
        {
          socialIds: [],
          socialIdsByTranslate: [],
          postContent: "",
          video: [
            {
              url: url,
              hover: false,
            },
          ],
          timeType: "0",
          publishTimeType: "0",
          publishTime: "",
          timeZoneId: "",
          productId: id,
          url: url,
        },
      ];
    }
    if (this.isCarry) {
      this.carryData = JSON.parse(sessionStorage.getItem("videoCarryData"));
      this.forms = [
        {
          socialIds: [],
          socialIdsByTranslate: [],
          postContent: this.carryData.title,
          video: [
            {
              url: this.carryData.playUrl,
              hover: false,
            },
          ],
          timeType: "0",
          publishTimeType: "0",
          publishTime: "",
          timeZoneId: "",
          productId: "",
          url: this.carryData.url,
        },
      ];
    }
  },
  methods: {
    // 返回列表
    goBack() {
      if (this.isCarry) {
        this.$tab.closeOpenPage({ path: "/ai/videoCarry" });
      } else {
        this.$tab.closeOpenPage({ path: "/issueManager" });
      }
    },
    getLanguageList() {
      getLanguages().then((response) => {
        const newList = response.data.map((item) => {
          return { ...item, select: false };
        });
        const oftenZero = newList.filter((item) => item.isOften === 1);
        const others = newList.filter((item) => item.isOften !== 1);
        this.languageList = oftenZero.concat(others);
      });
    },
    getDetail() {
      this.loading = true;
      getPublish(this.baseForm.id)
        .then((res) => {
          this.baseForm = res.data;
          if (res.data.jsonData) {
            this.forms = JSON.parse(res.data.jsonData);
            if (this.forms && this.forms.length > 0) {
              let productId = this.forms[0].productId;
              if (productId) {
                this.getProductDetail(productId);
              }
            }
          } else {
            this.$modal.msgError(this.$t("issueVideo.tipNoDetail"));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getProductDetail(productId) {
      getProductDetail(productId).then((res) => {
        this.detail = res.data;
        if (!this.detail) {
          this.$modal.msgError(this.$t("issueVideo.tipNoUrl"));
          this.$set(this.forms[0], "url", "");
          this.$set(this.forms[0], "productId", "");
          this.$set(this.forms[0], "video", []);
        }
      });
    },
    getListAccount() {
      listAccount()
        .then((response) => {
          this.accountList = response.rows;
          this.transformToCascaderData();
        })
        .catch(() => {});
    },
    transformToCascaderData() {
      const map = {};
      this.accountList.forEach((item) => {
        const profileId = item.profileId;
        // 如果 map 中没有这个 profileId，则创建新的项
        if (!map[profileId]) {
          map[profileId] = {
            value: profileId,
            label: item.topHeader || profileId,
            children: [],
          };
        }

        // 添加平台信息到 children 中
        map[profileId].children.push({
          value: item.id,
          label: item.displayName,
          platform: item.platform,
        });
      });
      this.newAccounts = Object.values(map);
    },
    getTimeZone() {
      listTimeZone()
        .then((res) => {
          this.timeOptions = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLanguage() {
      listLanguage().then((response) => {
        this.languageOptions = response.data;
      });
    },
  },
};
</script>

<style scoped>
</style>
