<template>
  <!--动态item-->
  <div class="social-item flex-column">
    <div class="top flex-column">
      <div class="flex-row-center">
        <el-avatar
          :src="avatarUrl"
          :size="48"
          style="margin-right: 8px;flex-shrink: 0;"
        ></el-avatar>
        <div>
          <div class="social-name"> {{ username }}</div>
          <div class="social-time"> {{ getTime()}}</div>
        </div>
      </div>
      <div class="express">
        <div
          :class="{hide:!isShow|| !isExpandable, expand: isShow}"
          ref="textElement"
        >{{ videoDescription }}</div>
        <div
          class="tips"
          @click="showInfo"
          v-if="!isShow && isExpandable"
        >{{ $t('expansion') }}</div>
        <div
          class="tips hide-btn"
          @click="showInfo"
          v-if="isShow"
        >{{ $t('collapse') }}</div>
      </div>
      <!-- 本地视频 -->
      <video
        v-if="item.videoUrl"
        ref="video"
        :src="getUrl()"
        controls
        class="video"
      ></video>
      <!-- Tiktok视频 -->
      <iframe
        v-else-if="platform === 'tiktok'"
        :src="getUrl()"
        frameborder="0"
        allowfullscreen
        class="video"
        height="300px"
        width="500px"
      ></iframe>
      <!-- 其他平台播放不了，提供跳出链接 -->
      <div
        v-else
        class="cellVideo"
      >
        <img
          style="width: 100%; height: 100%;"
          :src="getUrl()"
        >
        <div class="cricle" @click="openUrl(item.postUrl)">
          <i class="el-icon-caret-right"></i>
        </div>
      </div>
      <div class="bottom flex-row-center f-between">
        <div class="flex-column-center numBoxItem">
          <span class="bf">{{ getViewNum() || 0 }}</span>
          <span class="num">&nbsp;{{ $t('publicationStream.plays') }}</span>
        </div>
        <div class="flex-column-center numBoxItem">
          <span class="dz">{{ getLikeNum() || 0 }}</span>
          <span class="num">&nbsp;{{ $t('publicationStream.Likes') }}</span>
        </div>
        <div class="flex-column-center numBoxItem">
          <span class="pl">{{ getCommentNum() || 0 }}</span>
          <span class="num">&nbsp;{{ $t('publicationStream.comments') }}</span>
        </div>
        <div class="flex-column-center numBoxItem">
          <span class="zf">{{ getShareNum() || 0 }}</span>
          <span class="num">&nbsp;{{ $t('publicationStream.forwarding') }}</span>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="comment-box flex-column-center">
      <Comment
        :socialId="socialId"
        :postId="item.id"
      />
      <!-- 因为有延迟，所以评论过后暂时不需要刷新了 -->
      <!-- @refresh="queryComments" -->
      <div
        class="query-comment"
        @click="queryComments"
        v-if="!showComment"
      >{{ $t('publicationStream.lookAllComments') }} ></div>
      <div
        v-loading="listLoading"
        style="width: 500px"
      >
        <CommentList
          :comments="commentList"
          :socialId="socialId"
          :postId="item.id"
        />
        <!-- 因为有延迟，所以评论过后暂时不需要刷新了 -->
        <!-- @refresh="queryComments" -->
      </div>
      <div
        class="query-comment"
        @click="showComment=!showComment"
        v-if="commentList.length > 0 && showComment"
      >{{ $t('publicationStream.close') }} <i class="el-icon-arrow-up"></i></div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/index";
import { pubComments, queryComments } from "../../api/ai/publish";
import CommentList from "@/components/Comment/CommentList.vue";
import Comment from "@/components/Comment/Comment.vue";
export default {
  components: { CommentList, Comment },
  props: {
    // 角色编号
    item: {
      type: Object,
      required: true,
    },
    avatarUrl: {
      type: String,
      default: "",
    },
    username: {
      type: String,
      default: "",
    },
    platform: {
      type: String,
      default: "",
    },
    socialId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      openDetail: false, // 详情对话框
      isShow: false,
      isExpandable: false,
      message: "",
      commentLoading: false,
      commentList: [],
      listLoading: false,
      showComment: false,
    };
  },
  computed: {
    videoDescription() {
      return this.getDes();
    },
  },
  mounted() {
    // 获取文本行数并设置isExpandable的值
    this.checkExpandable();
  },
  watch: {
    videoDescription: "checkExpandable", // 监听内容变化，重新检查是否可展开
  },
  methods: {
    openUrl(url){
      window.open(url)
    },
    getUrl() {
      if (this.item.videoUrl) {
        return this.item.videoUrl;
      }
      if (this.platform === "tiktok") {
        return this.item.embedUrl;
      } else if (this.platform === "youtube") {
        return this.item.thumbnailUrl;
      } else if (this.platform === "facebook") {
        return this.item.fullPicture;
      } else {
        return this.item.thumbnailUrl;
      }
    },
    // 发布时间
    getTime() {
      if (this.platform === "tiktok") {
        return formatDate(this.item.created);
      } else if (this.platform === "youtube") {
        return formatDate(this.item.created);
      } else {
        return formatDate(this.item.created);
      }
    },
    // 文案
    getDes() {
      if (this.platform === "tiktok") {
        return this.item.post;
      } else if (this.platform === "youtube") {
        return this.item.title;
      } else {
        return this.item.content;
      }
    },
    // 播放数
    getViewNum() {
      if (this.platform === "tiktok") {
        return this.item.videoViews;
      } else if (this.platform === "twitter") {
        const data = this.item.organicMetrics || {};
        return data.impressionCount;
      } else {
        return this.item.videoViews;
      }
    },
    // 点赞数
    getLikeNum() {
      if (this.platform === "tiktok") {
        return this.item.likeCount;
      } else if (this.platform === "twitter") {
        const data = this.item.organicMetrics || {};
        return data.likeCount;
      } else {
        return this.item.likeCount;
      }
    },
    // 评论数
    getCommentNum() {
      if (this.platform === "tiktok") {
        return this.item.commentsCount;
      } else if (this.platform === "twitter") {
        const data = this.item.organicMetrics || {};
        return data.replyCount;
      } else {
        return this.item.commentCount;
      }
    },
    // 转发数
    getShareNum() {
      if (this.platform === "tiktok") {
        return this.item.shareCount;
      } else if (this.platform === "twitter") {
        const data = this.item.organicMetrics || {};
        return data.retweetCount;
      } else {
        return this.item.shareCount;
      }
    },
    commentSumit() {
      if (!this.message) {
        this.$modal.msgError(this.$t('publicationStream.commentTip'));
        return;
      }
      this.commentLoading = true;
      pubComments({
        socialId: this.socialId,
        postId: this.item.id,
        message: this.message,
      })
        .then((res) => {
          this.$modal.msgSuccess(this.$t('publicationStream.commentSuccess'));
          this.message = "";
          this.queryComments();
        })
        .finally(() => {
          this.commentLoading = false;
        });
    },
    queryComments() {
      this.listLoading = true;
      queryComments({
        socialId: this.socialId,
        postId: this.item.id,
      })
        .then((res) => {
          this.commentList = res.data;
          this.showComment = true;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },

    // 全文展开收起
    showInfo() {
      this.isShow = !this.isShow;
    },
    checkExpandable() {
      this.$nextTick(() => {
        const actualLineCount = this.getActualLineCount();
        this.isExpandable = actualLineCount >= 3;
      });
    },
    getActualLineCount() {
      // 使用$refs获取文本所在的DOM元素引用
      const textElement = this.$refs.textElement;
      console.log("textElement", textElement);
      if (textElement) {
        // 获取元素的高度和行高
        const clientHeight = textElement.clientHeight;
        const lineHeight = parseInt(
          window.getComputedStyle(textElement).lineHeight
        );
        // 计算实际行数
        const actualLineCount = Math.floor(clientHeight / lineHeight);
        console.log("actualLineCount", actualLineCount);
        // 返回实际行数
        return actualLineCount;
      }
      // 默认返回0行，表示未获取到实际行数
      return 0;
    },
  },
};
</script>
<style scoped lang="scss">
.social-item {
  width: 548px;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.top {
  padding: 24px 24px 0;
  .social-name {
    height: 20px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 20px;
    text-align: left;
    font-style: normal;
  }

  .social-time {
    height: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 18px;
    text-align: left;
    font-style: normal;
    margin-top: 10px;
  }
}

.bottom {
  width: 380px;
  padding: 0 0px;
  margin-top: 16px;
  line-height: 12px;
  align-self: center;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-style: normal;
  .bf {
    color: #016fee;
  }
  .dz {
    color: #ee2201;
  }
  .pl {
    color: #b500e4;
  }
  .zf {
    color: #ccc51a;
  }
  .numBoxItem {
    width: 95px;
  }
}

// 展开收起
.express {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 8px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 18px;
  text-align: left;
  font-style: normal;
}

.tips {
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #0078ff;
  line-height: 25px;
  padding: 0 10px;
  font-size: 16px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%
  );
  background: white;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

// 收起 按钮
.hide-btn {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  color: #0078ff;
  line-height: 25px;
  font-size: 16px;
  cursor: pointer;
}

// 展开 文字
.hide {
  word-break: break-word; //换行模式
  overflow: hidden;
  text-overflow: ellipsis; //修剪文字，超过2行显示省略号
  display: -webkit-box;
  -webkit-line-clamp: 2; //此处为上限行数
  line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-line; /* 保留换行符 */
}

// 动态展开时的样式
.expand {
  -webkit-line-clamp: unset; // 取消行数限制
  line-clamp: unset;
  white-space: pre-line; /* 保留换行符 */
}

.num {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin-top: 8px;
}

.video {
  width: 500px;
  height: 300px;
  align-self: center;
  margin-top: 16px;
}
.comment-box {
  padding: 0 24px 24px;
  box-sizing: border-box;
}
.query-comment {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-top: 16px;
  cursor: pointer;
}

.cellVideo {
  width: 500px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  margin-top: 16px;

  .cricle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6);
    cursor: pointer;

    .el-icon-caret-right {
      color: #fff;
      font-size: 18px;
    }
  }
}
</style>
