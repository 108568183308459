var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-page" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "video-container flex-center",
      },
      [
        _vm.videoList.length > 0
          ? _c("div", { staticClass: "flex-center" }, [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "video-frame-container flex-column" },
                  [
                    _vm.isOverWall
                      ? _c("iframe", {
                          attrs: {
                            src: _vm.getVideoUrl(_vm.currentVideo),
                            frameborder: "0",
                            allow: "autoplay; encrypted-media",
                            allowfullscreen: "",
                          },
                        })
                      : _c("video", {
                          staticClass: "video-player",
                          attrs: {
                            src: _vm.currentVideo.url,
                            controls: "",
                            autoplay: "",
                          },
                        }),
                    _c(
                      "div",
                      {
                        staticClass: "bottom-content flex-row-center f-between",
                      },
                      [
                        _c("el-avatar", {
                          staticClass: "f-shrink",
                          attrs: { size: 48, src: _vm.currentVideo.avatarUrl },
                        }),
                        _c(
                          "span",
                          {
                            staticClass:
                              "name ml10 f-grow single-line-ellipsis",
                          },
                          [_vm._v(_vm._s(_vm.currentVideo.displayName))]
                        ),
                        _c("span", { staticClass: "time ml10 f-shrink" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateDiff")(_vm.currentVideo.publishTime)
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.nextVideo
                  ? _c("div", { staticClass: "next-video-preview" }, [
                      _vm.isOverWall
                        ? _c("iframe", {
                            attrs: {
                              src: _vm.getNextVideoUrl(_vm.nextVideo),
                              frameborder: "0",
                              allowfullscreen: "",
                            },
                          })
                        : _c("video", {
                            staticClass: "video-player",
                            attrs: { src: _vm.nextVideo.url },
                          }),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "video-nums flex-column-center f-between" },
                [
                  _c("div", { staticClass: "icon-container flex-center" }, [
                    _c("i", {
                      staticClass: "iconfont socialType-icon",
                      class: [
                        "icon-" + _vm.currentVideo.socialType,
                        _vm.currentVideo.socialType,
                      ],
                    }),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "flex-column-center mt10" }, [
                      _vm._m(0),
                      _c("span", { staticClass: "video-nums-text" }, [
                        _vm._v(_vm._s(_vm.currentVideo.views || 0)),
                      ]),
                    ]),
                    _c("div", { staticClass: "flex-column-center mt10" }, [
                      _vm._m(1),
                      _c("span", { staticClass: "video-nums-text" }, [
                        _vm._v(_vm._s(_vm.currentVideo.likes || 0)),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "flex-column-center mt10 pointer",
                        on: { click: _vm.showComment },
                      },
                      [
                        _vm._m(2),
                        _c("span", { staticClass: "video-nums-text" }, [
                          _vm._v(_vm._s(_vm.currentVideo.comments || 0)),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "flex-column-center mt10" }, [
                      _vm._m(3),
                      _c("span", { staticClass: "video-nums-text" }, [
                        _vm._v(_vm._s(_vm.currentVideo.shares || 0)),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm.isShowComment
                ? _c(
                    "div",
                    {
                      ref: "scrollableDiv",
                      staticClass:
                        "video-frame-container video-comment flex-column",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "comment-head flex-row-center" },
                        [
                          _vm._v(" 评论内容 "),
                          _c("div", { staticClass: "comment-head-num" }, [
                            _vm._v(_vm._s(_vm.currentVideo.comments || 0)),
                          ]),
                          _c("i", {
                            staticClass: "el-icon-close comment-head-close",
                            on: {
                              click: function ($event) {
                                _vm.isShowComment = false
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "comment-bottom flex-column" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.commentLoading,
                                  expression: "commentLoading",
                                },
                              ],
                              staticClass: "f-grow",
                              staticStyle: { "overflow-y": "auto" },
                            },
                            [
                              _vm.commentList.length > 0
                                ? _c(
                                    "div",
                                    [
                                      _c("CommentList", {
                                        attrs: {
                                          comments: _vm.commentList,
                                          postId: _vm.currentVideo.id,
                                          socialAccounts:
                                            _vm.getCurrentAccounts,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.commentLoading &&
                              _vm.commentList.length === 0
                                ? _c("el-empty", {
                                    attrs: { description: "暂无评论" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.socialAccounts.length > 0 &&
                          _vm.getCurrentAccounts.length > 0
                            ? _c("Comment", {
                                attrs: {
                                  socialAccounts: _vm.getCurrentAccounts,
                                  postId: _vm.currentVideo.id,
                                },
                                on: { commentSuccess: _vm.commentSuccess },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "video-controls" }, [
                _vm.currentVideoIndex !== 0
                  ? _c(
                      "div",
                      {
                        staticClass: "arrowButton flex-center",
                        on: { click: _vm.playPreviousVideo },
                      },
                      [_c("i", { staticClass: "el-icon-arrow-up icon" })]
                    )
                  : _vm._e(),
                _vm.currentVideoIndex == 0
                  ? _c("div", {
                      staticStyle: { width: "58px", height: "58px" },
                    })
                  : _vm._e(),
                _vm.videoList.length > 1
                  ? _c(
                      "div",
                      {
                        staticClass: "arrowButton flex-center",
                        on: { click: _vm.playNextVideo },
                      },
                      [_c("i", { staticClass: "el-icon-arrow-down icon" })]
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        !_vm.loading && _vm.videoList.length === 0
          ? _c(
              "el-empty",
              {
                attrs: {
                  description: "暂无发布流数据",
                  "image-size": 200,
                  image: require("@/assets/logo/logo.png"),
                },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "gradient-button",
                    on: { click: _vm.goPublish },
                  },
                  [_vm._v("去发布")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "video-nums-circle flex-center" }, [
      _c("i", { staticClass: "iconfont icon-font icon-bofang1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "video-nums-circle flex-center" }, [
      _c("i", { staticClass: "iconfont icon-font icon-dianzan" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "video-nums-circle flex-center" }, [
      _c("i", { staticClass: "iconfont icon-font icon-pinglun" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "video-nums-circle flex-center" }, [
      _c("i", { staticClass: "iconfont icon-font icon-zhuanfa" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }