<template>
  <div>
    <div
      v-for="(item, key) in socialTimeMap"
      :key="key"
      class="accountItem"
      @mouseenter="mouseenterDel(item)"
      @mouseleave="mouseleaveDel(item)"
    >
      <!-- <p>ID: {{ key }}</p> -->
      <div class="accountItemBoxFlex">
        <div class="accountItemBox mr16">
          <el-avatar
            :size="32"
            :src="item.userImage"
          ></el-avatar>
          <div class="mediaName single-line-ellipsis">{{ item.displayName }}</div>
        </div>
        <el-select
          v-model="item.publishTimeType"
          :placeholder="$t('issueItem.tipTimezone')"
          filterable
          @change="clearPublishTime(item)"
        >
          <el-option
            v-for="time in publishTimeOptions"
            :key="time.value"
            :label="time.label"
            :value="time.value"
          >
          </el-option>
        </el-select>
        <div v-if="item.publishTimeType === '1'">
          ———
          <el-date-picker
            v-if="timeValues[item.id]"
            v-model="timeValues[item.id].publishTime"
            type="datetime"
            :placeholder="$t('issueItem.tipDate')"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="changePickerNormal(item)"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </div>
        <div v-if="item.publishTimeType === '2'">
          ———
          <el-select
            v-model="item.timeZoneId"
            :placeholder="$t('issueItem.tipTimezone')"
            filterable
            @change="clearTime(item)"
          >
            <el-option
              v-for="time in timeOptions"
              :key="time.id"
              :label="time.rawFormat"
              :value="time.id"
              class="ellipsis-option"
            ></el-option>
          </el-select>
          ———
          <el-date-picker
            v-if="timeValues[item.id]"
            v-model="timeValues[item.id].publishTime"
            type="datetime"
            :placeholder="$t('issueItem.tipDate')"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
            @change="changePicker(item)"
            :disabled="item.publishTimeType === '2' && !item.timeZoneId"
          ></el-date-picker>
        </div>
      </div>
      <i
        v-if="mouseenterId === item.id"
        class="iconfont icon-delete"
        @click="deleteAccount(item)"
      ></i>
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/index";
export default {
  name: "SocialItem",
  components: {},
  props: {
    template: {
      type: Object,
      required: true,
    },
    timeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pickerOptions: {
        // 禁用掉今天之前的日期不让选择(不包括今天)
        disabledDate(time) {
          const today = new Date();
          today.setHours(0, 0, 0, 0); // 设置为今天的零点
          return time.getTime() < today.getTime();
        },
      },
      mouseenterId: "",
      socialTimeMap: null,
      timeValues: {},
      // socialTimeMap: this.template,
      publishTimeOptions: [
        {
          value: "0",
          label: this.$t("issueItem.issueNow"),
        },
        {
          value: "1",
          label: this.$t("issueItem.localTime"),
        },
        {
          value: "2",
          label: this.$t("issueItem.standardTime"),
        },
      ],
    };
  },
  watch: {
    template: {
      handler(val) {
        this.socialTimeMap = val;
        if (val.length) {
          this.initializeTimeValues(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  methods: {
    initializeTimeValues(val) {
      for (const key in val) {
        if (val.hasOwnProperty(key)) {
          const item = val[key];
          if (!this.timeValues[item.id]) {
            this.$set(this.timeValues, item.id, {
              publishTime: item.publishTime || "",
            });
          }
        }
      }
    },
    mouseleaveDel(val) {
      this.mouseenterId = "";
    },
    mouseenterDel(val) {
      this.mouseenterId = val.id;
    },
    deleteAccount(val) {
      this.$emit("deleteMap", val);
      delete this.socialTimeMap[val.id];
      this.mouseenterId = "";
      // this.socialTimeMap = this.socialTimeMap.filter(item => item.id !== val.id);
    },
    //清空时间，必须先选择时区才能选择时间
    clearPublishTime(item) {
      if (item.publishTimeType === "2" && !item.timeZoneId) {
        item.publishTime = "";
      }
      this.refreshMap();
    },
    clearTime(item) {
      item.publishTime = "";
      this.refreshMap();
    },
    changePickerNormal(item) {
      console.log(123);
      const startAt = new Date(this.timeValues[item.id].publishTime).getTime();
      const now = new Date();
      now.setHours(now.getHours() + 1); // 当前时间加1小时
      if (startAt < now.getTime()) {
        this.$set(item, "publishTime", this.formatDate(now));
      } else {
        this.$set(item, "publishTime", this.timeValues[item.id].publishTime);
      }
      this.refreshMap();
    },
    changePicker(item) {
      // 获取用户选择的时区偏移量
      let timeZone =
        this.timeOptions.find((option) => option.id === item.timeZoneId) || {};
      const timeZoneOffset = timeZone.utc || "+08:00"; // 示例： '+08:00' or '-12:00'
      console.log("timeZoneOffset", timeZoneOffset);
      // 将时区偏移量转换为小时数
      const offsetHours = this.convertTimezoneOffsetToHours(timeZoneOffset);

      // 以时区偏移计算选择的时间
      const startAt = this.applyTimezoneOffset(
        new Date(this.timeValues[item.id].publishTime),
        offsetHours
      ).getTime();

      // 获取当前UTC时间，并加1小时
      const now = new Date(); // 当前时间
      const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000); // UTC时间
      utcNow.setHours(utcNow.getHours() + 1);
      const nowWithOffset = this.applyTimezoneOffset(utcNow, offsetHours);

      // 如果选择的时间小于当前时区时间+1小时，则重置为当前时区时间+1小时
      if (startAt < nowWithOffset.getTime()) {
        // item.publishTime = this.formatDate(nowWithOffset);
        this.$set(item, "publishTime", this.formatDate(nowWithOffset));
      } else {
        this.$set(item, "publishTime", this.timeValues[item.id].publishTime);
      }
      // console.log('item.publishTime', item.publishTime);
      this.refreshMap();
    },

    // 将时区偏移量（如 +08:00）转换为小时
    convertTimezoneOffsetToHours(offset) {
      const sign = offset.startsWith("-") ? -1 : 1;
      const [hours, minutes] = offset.slice(1).split(":").map(Number);
      return sign * (hours + minutes / 60);
    },

    // 将日期对象应用时区偏移量
    applyTimezoneOffset(date, offsetHours) {
      const dateWithOffset = new Date(date);
      dateWithOffset.setHours(dateWithOffset.getHours() + offsetHours);
      return dateWithOffset;
    },

    formatDate(date) {
      // 格式化日期为 yyyy-MM-dd HH:mm:ss 格式
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    refreshMap() {
      this.initializeTimeValues(this.socialTimeMap);
      this.$emit("refreshMap", this.socialTimeMap); // 传递当前选中的 item
      // console.log(this.socialTimeMap);
    },
  },
};
</script>
<style lang="scss" scoped>
.accountItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.accountItemBoxFlex {
  display: flex;
  align-items: center;
}
.accountItemBox {
  display: flex;
  align-items: center;
}
.icon-delete {
  color: #ff2f2f;
  font-size: 14px;
  cursor: pointer;
}
.mediaName {
  width: 140px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-left: 9px;
}
</style>
