var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info-container" }, [
    _c(
      "div",
      { staticClass: "flexMessage" },
      [
        _c("info-social", { attrs: { mediaTypeList: _vm.mediaTypeList } }),
        _c("div", { staticClass: "rightMessage" }, [
          _vm.isShowMsg
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.socialLoading,
                      expression: "socialLoading",
                    },
                  ],
                  staticClass: "infoStreamMsgContent flex-column-center",
                  staticStyle: { height: "100%", "overflow-y": "auto" },
                },
                [
                  _c(
                    "div",
                    _vm._l(_vm.socialList, function (item) {
                      return _c(
                        "div",
                        { key: item.id },
                        [
                          _c("TiktokItem", {
                            staticStyle: { "margin-bottom": "5px" },
                            attrs: {
                              item: item,
                              avatarUrl: _vm.avatarUrl,
                              username: _vm.username,
                              platform: _vm.platform,
                              socialId: _vm.queryParamsSocial.socialId,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm.socialList.length == 0
                    ? _c("el-empty", { attrs: { "image-size": 200 } })
                    : _vm._e(),
                ],
                1
              )
            : _c("div", { staticClass: "infoStreamMsgContent flex-center" }, [
                _vm.logo
                  ? _c("img", { staticClass: "logo", attrs: { src: _vm.logo } })
                  : _vm._e(),
              ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }