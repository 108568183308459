var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-page" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "video-container flex-center",
      },
      [
        _vm.videoList.length > 0
          ? _c("div", { staticClass: "flex-center" }, [
              _c(
                "div",
                { ref: "carouselContainer", staticClass: "carousel-container" },
                [
                  _c(
                    "el-carousel",
                    {
                      ref: "carousel",
                      attrs: {
                        height: "800px",
                        autoplay: false,
                        direction: "vertical",
                        "indicator-position": "none",
                      },
                      on: { change: _vm.onSlideChange },
                    },
                    _vm._l(_vm.videoList, function (video, index) {
                      return _c("el-carousel-item", { key: index }, [
                        _c("div", { staticClass: "flex-row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "video-frame-container flex-column f-between",
                            },
                            [
                              _vm.isOverWall && _vm.currentVideoIndex === index
                                ? _c("iframe", {
                                    ref: "iframeRefs",
                                    refInFor: true,
                                    attrs: {
                                      src: _vm.getVideoUrl(video),
                                      frameborder: "0",
                                      allow: "autoplay; encrypted-media",
                                      allowfullscreen: "",
                                    },
                                  })
                                : _vm.currentVideoIndex === index
                                ? _c("video", {
                                    ref: "videoRefs",
                                    refInFor: true,
                                    staticClass: "video-player-info",
                                    attrs: {
                                      src: video.url,
                                      controls: "",
                                      autoplay: "",
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "bottom-content flex-row-center f-between f-shrink",
                                },
                                [
                                  _c("el-avatar", {
                                    staticClass: "f-shrink",
                                    attrs: { size: 48, src: video.avatarUrl },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "name ml10 f-grow single-line-ellipsis",
                                    },
                                    [_vm._v(_vm._s(video.displayName))]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "time ml10 f-shrink" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateDiff")(video.publishTime)
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "video-nums flex-column-center f-between",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "icon-container flex-center" },
                                [
                                  _c("i", {
                                    staticClass: "iconfont socialType-icon",
                                    class: [
                                      "icon-" + video.socialType,
                                      video.socialType,
                                    ],
                                  }),
                                ]
                              ),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "flex-column-center mt10" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "video-nums-circle flex-center",
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-font icon-bofang1",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "video-nums-text" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            video.views && video.views > 0
                                              ? video.views
                                              : 0
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex-column-center mt10" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "video-nums-circle flex-center",
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-font icon-dianzan",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "video-nums-text" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            video.likes && video.likes > 0
                                              ? video.likes
                                              : 0
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-column-center mt10 pointer",
                                    on: { click: _vm.showComment },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "video-nums-circle flex-center",
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-font icon-pinglun",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "video-nums-text" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            video.comments && video.comments > 0
                                              ? video.comments
                                              : 0
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex-column-center mt10" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "video-nums-circle flex-center",
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-font icon-zhuanfa",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "video-nums-text" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            video.shares && video.shares > 0
                                              ? video.shares
                                              : 0
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.isShowComment
                ? _c(
                    "div",
                    {
                      ref: "scrollableDiv",
                      staticClass:
                        "video-frame-container video-comment flex-column",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "comment-head flex-row-center" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("publicationStream.commentContent")
                              ) +
                              " "
                          ),
                          _c("div", { staticClass: "comment-head-num" }, [
                            _vm._v(
                              _vm._s(
                                _vm.commentNum || _vm.currentVideo.comments || 0
                              )
                            ),
                          ]),
                          _c("i", {
                            staticClass: "el-icon-close comment-head-close",
                            on: {
                              click: function ($event) {
                                _vm.isShowComment = false
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "comment-bottom flex-column" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.commentLoading,
                                  expression: "commentLoading",
                                },
                              ],
                              staticClass: "f-grow",
                              staticStyle: { "overflow-y": "auto" },
                            },
                            [
                              _vm.commentList.length > 0
                                ? _c(
                                    "div",
                                    [
                                      _c("CommentList", {
                                        attrs: {
                                          comments: _vm.commentList,
                                          postId: _vm.currentVideo.id,
                                          socialAccounts:
                                            _vm.getCurrentAccounts,
                                        },
                                        on: {
                                          commentSuccess: _vm.commentSuccess,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.commentLoading &&
                              _vm.commentList.length === 0
                                ? _c("el-empty", {
                                    attrs: {
                                      description: _vm.$t(
                                        "publicationStream.noComment"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.socialAccounts.length > 0 &&
                          _vm.getCurrentAccounts.length > 0
                            ? _c("Comment", {
                                attrs: {
                                  socialAccounts: _vm.getCurrentAccounts,
                                  postId: _vm.currentVideo.id,
                                },
                                on: { commentSuccess: _vm.commentSuccess },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "video-controls" }, [
                _vm.currentVideoIndex !== 0
                  ? _c(
                      "div",
                      {
                        staticClass: "arrowButton flex-center",
                        on: { click: _vm.playPreviousVideo },
                      },
                      [_c("i", { staticClass: "el-icon-arrow-up icon" })]
                    )
                  : _vm._e(),
                _vm.currentVideoIndex == 0
                  ? _c("div", {
                      staticStyle: { width: "58px", height: "58px" },
                    })
                  : _vm._e(),
                _vm.videoList.length > 1
                  ? _c(
                      "div",
                      {
                        staticClass: "arrowButton flex-center",
                        on: { click: _vm.playNextVideo },
                      },
                      [_c("i", { staticClass: "el-icon-arrow-down icon" })]
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        !_vm.loading && _vm.videoList.length === 0
          ? _c(
              "el-empty",
              {
                attrs: {
                  description: _vm.$t("publicationStream.noData"),
                  "image-size": 200,
                  image: require("@/assets/logo/logo.png"),
                },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "gradient-button",
                    on: { click: _vm.goPublish },
                  },
                  [_vm._v(_vm._s(_vm.$t("publicationStream.goPublish")))]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }